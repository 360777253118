import PropTypes from 'prop-types'

import styles from './cancelButton.module.css'

function CancelButton(props) {
  return (
    <button
      className={styles.cancel}
      title={props.title || null}
      onClick={props.onClick}
    >×</button>
  )
}


CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default CancelButton
