import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'

import styles from '../boardDetail/boardDetail.module.css'

import UserShortDetail from '../userShortDetail/UserShortDetail'
import UpdateFolderButton from '../updateFolderButton/UpdateFolderButton'
import BoardsList from '../boardsList/BoardsList'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import NavigationBar from '../navigationBar/NavigationBar'


const FOLDER_DETAIL_QUERY = gql`
  query FolderDetailQuery(
    $username: String!,
    $folderSlug: String!,
  ) {
    me {
      id
    }
    user(username: $username) {
      id
      folder(slug: $folderSlug) {
        id
        name
        secret
        boards {
          totalCount
          edges {
            id
            secret
            slug
            title
            caption
            pins {
              totalCount
            }
            cover1(minWidth: 104, minHeight: 104, format: "jpeg")
            cover2(minWidth: 104, minHeight: 104, format: "jpeg")
            cover3(minWidth: 104, minHeight: 104, format: "jpeg")
            cover4(minWidth: 104, minHeight: 104, format: "jpeg")
            cover5(minWidth: 104, minHeight: 104, format: "jpeg")
          }
        }
      }
    }
  }
`


function FolderDetail(props) {
  let { username, folderSlug } = useParams()
  const { loading, data, error } = useQuery(FOLDER_DETAIL_QUERY, {
    variables: { 
      username,
      folderSlug,
    }
  })
  //console.log('FolderDetail', username, folderSlug, data, error)

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  let owner = !!data.me && data.me.id === data.user.id
  let folder = data.user.folder

  return (
    <section id="content">
      <NavigationBar
        backlink={`/${username}/folders/`}
      />
      <UserShortDetail username={username}>
        <UpdateFolderButton
          owner={owner}
          folderID={folder.id}
          title="Edit folder"
        />
      </UserShortDetail>
      <header className={styles.header}>
        <h1>{folder.name}</h1>
        <p 
          className={styles.pinCount}
        >{folder.boards.totalCount} {folder.boards.totalCount === 1 ? " board" : " boards"}</p>
        {folder.secret ? <p>This folder is private</p> : null}
      </header>
      <section className={styles.boardsSection + " columned"}>
        <BoardsList
          boards={folder.boards.edges}
          owner={owner}
          folderID={folder.id}
          baseLink={`/${username}/folders/${folderSlug}/`}
        />
      </section>
    </section>
  )
}


export default FolderDetail
