import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './headerSearch.module.css'

function HeaderSearch() {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  return (
    <form
      className={styles.search}
      onSubmit={(e) => {
        e.preventDefault()
        let url = `/search/?query=${encodeURIComponent(search)}`
        setSearch('')
        navigate(url)
      }}
    >
      <label
        className={styles.searchLabel}
      > 
        <span className={styles.magnifying} />
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search on Pinpict"
        />
      </label>
    </form>
  )
}

export default HeaderSearch
