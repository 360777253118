
import PropTypes from 'prop-types'

import {
  useQuery,
  NetworkStatus,
} from '@apollo/client'


import LastPinsList from '../lastPinsList/LastPinsList'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'

import { SEARCH_PINS_QUERY } from '../search/Search'

import styles from '../boardDetail/boardDetail.module.css'


function SearchPinsByQuery({ query }) {
  
  //console.log('SearchPinsByQuery', query)
  const { data, error, fetchMore, networkStatus } = useQuery(SEARCH_PINS_QUERY, {
    variables: { 
      search: query,
      first: 50,
      after: null,
    },
  }) 
  
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  if (networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables) {
    return <Spinner />
  }

  let pins = data.pins
  let visitorID = data.me ? data.me.id : null


  return (
    <section id="content">
      <header className={styles.header}>
        <h1>Found {pins.totalCount} {pins.totalCount === 1 ? " pin" : " pins"} for "{query}"</h1>
      </header>
      <LastPinsList
        pins={pins.edges}
        visitorID={visitorID}
        hasNextPage={pins.pageInfo.hasNextPage}
        loadMore={() => {
          return fetchMore({
            variables: { 
              search: query,
              first: 50,
              after: pins.pageInfo.endCursor,
            },
            notifyOnNetworkStatusChange: true
          })
        }}
        loadingMore={networkStatus === NetworkStatus.fetchMore }
        baseLink="/search/"
      />
    </section>
  )
}

SearchPinsByQuery.propTypes = {
  query: PropTypes.string.isRequired,
}

export default SearchPinsByQuery
