
import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams } from 'react-router-dom'

import styles from '../userBoards/userBoards.module.css'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import FoldersList from '../foldersList/FoldersList'
import PrivateFoldersWrapper from '../privateFoldersWrapper/PrivateFoldersWrapper'

const USER_FOLDERS_QUERY = gql`
  query UserFoldersQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      folders {
        edges {
          id
          secret
          slug
          name
          cover1(minWidth: 104, minHeight: 104, format: "jpeg")
          cover2(minWidth: 104, minHeight: 104, format: "jpeg")
          cover3(minWidth: 104, minHeight: 104, format: "jpeg")
          cover4(minWidth: 104, minHeight: 104, format: "jpeg")
          boards {
            totalCount
          }
        }
      }
    }
  }
`

function UserFolders() {
  let { username } = useParams()
  const { loading, data, error } = useQuery(USER_FOLDERS_QUERY, {
    variables: { username },
  })

  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }
  //console.log('UserFolders', data)
  let owner = !!data.me && data.me.id === data.user.id

  return (
    <>
      <section className={styles.boardsSection + " columned"}>
        <FoldersList
          folders={data.user.folders.edges.filter(node => ! node.secret)}
          owner={owner}
        />
      </section>
      <PrivateFoldersWrapper
        folders={data.user.folders.edges.filter(node => node.secret)}
        owner={owner}
      />
    </>
  )

}

export default UserFolders
