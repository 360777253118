import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import AccessibilityText from '../accessibilityText/AccessibilityText'

import styles from './navigationBar.module.css'

function NavigationBar({ title, backlink, backlinkTitle, count, filteredCount = null, children }) {
  //console.log('navigation', props)
  return (
    <nav className={styles.navigation}>
      {backlink ? (<Link
        className={styles.link}
        to={backlink}
        title={backlinkTitle || "back"}
      >
        <AccessibilityText text="Back" />
      </Link>) : null }
        <h2>{title}</h2> <span className={styles.count}>{filteredCount !== null ? `${filteredCount}/${count} (filtered)` : count}</span>
      {children}
    </nav>
  )
}

export default NavigationBar


NavigationBar.propTypes = {
  title: PropTypes.string,
  backlink: PropTypes.string,
  backlinkTitle: PropTypes.string,
  count: PropTypes.number,
  filteredCount: PropTypes.number,
}
