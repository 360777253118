import { useQuery } from '@apollo/client'

import { NavLink } from 'react-router-dom'

import UserMenuButton from '../userMenuButton/UserMenuButton'

import styles from './headerLinks.module.css'


import { AUTHENTICATED_QUERY } from '../app/App'

function HeaderLinks() {
  
  const { loading, data, error } = useQuery(AUTHENTICATED_QUERY)
  
  if (loading) return null
  if (error) console.error(error)
  
  // If user is authenticated show user menu
  if (data && data.me) {
    return (
      <ul
        className={styles.headerLinks}
      >
        <li><UserMenuButton /></li>
      </ul>
    )
  }

  // Else show login Link
  return (
    <ul
      className={styles.headerLinks}
    >
      <li><NavLink
        to="/login/"
      className={({ isActive }) => (isActive ? styles.active : "")}
      >Login</NavLink></li>
      <li><NavLink
        to="/signup/"
      className={({ isActive }) => (isActive ? styles.active : "")}
      >Sign up</NavLink></li>
    </ul>
  )
}


export default HeaderLinks
