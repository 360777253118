import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './createPinForm.module.css'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'
import TagInput from '../tagInput/TagInput'
import Button from '../button/Button'
import DeleteButton from '../deleteButton/DeleteButton'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import PinRate from '../pinRate/PinRate'

function CreatePinForm(props) {
  //console.log('CreatePinForm', props)

  const lastCaption = localStorage.getItem(`lastCaption:${props.board}`)
  const lastTitle = localStorage.getItem(`lastTitle:${props.board}`)
  const lastTags = localStorage.getItem(`lastTags:${props.board}`)

  const titleRef = useRef(null)
  const captionRef = useRef(null)
  const previewRef = useRef(null)
  const [previewSize, setPreviewSize] = useState(null)

  const loadPreviewSize = () => {
    const { naturalWidth, naturalHeight } = previewRef.current
    setPreviewSize({ width: naturalWidth, height: naturalHeight })
  }

  const setPrevious = () => {
    /*
      * Using document.execCommand instead of replacing state value
      * allows us to keep browser undo history
      */
    if (lastTitle) {
      titleRef.current.focus()
      document.execCommand("selectAll")
      document.execCommand("insertText", false, lastTitle)
    }

    if (lastCaption) {
      captionRef.current.focus()
      document.execCommand("selectAll")
      document.execCommand("insertText", false, lastCaption)
    }

    if (lastTags) {
      const tags = lastTags.split(' ')
      props.setTags(tags)
    }
  }
  
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.previewWrapper}>
        <div className={styles.previewImageWrapper}>
          <img 
            src={props.preview}
            alt="" 
            ref={previewRef}
            onLoad={loadPreviewSize}
          />
        </div>
        <PinRate 
          rating={props.rating}
          setRating={props.setRating}
          className={styles.rating}
        />
        <div className={styles.previewSize}>
          {((props.width && props.height) || previewSize) ? 
            `${props.width || previewSize.width} × ${props.height || previewSize.height} px`
          : "…"}
        </div>
      </div>
      <form
        id={props.form}
        onSubmit={props.onSubmit}
        className={styles.pinForm}
      >
        {props.delete ? (
          <DeleteButton
            title="Delete pin"
            onClick={props.delete}
          />
        ) : null}
        <div className={styles.fields}>
          <FormRequiredFields />
          {props.handleBoardChange ? (<FieldWrapper>
            <label htmlFor="id-board">Board <FormRequiredField /></label>
              <select
                id="id-board"
                name="board"
                value={props.board}
                onChange={props.handleBoardChange}
                required
              >
                <optgroup label="Last used boards">
                  {props.recentBoards.map(board =>
                    <option
                      disabled={props.disabledBoardsIDs?.includes(board.id)} 
                      key={board.id}
                      value={board.id}
                    >{board.title}{board.folder ? ` [${board.folder.name}]` : null}</option>
                  )}
                </optgroup>
                <optgroup label="All boards">
                {props.boards.map(board =>
                  <option
                    disabled={props.disabledBoardsIDs?.includes(board.id)} 
                    key={board.id}
                    value={board.id}
                  >{board.title}{board.folder ? ` [${board.folder.name}]` : null}</option>
                )}
                </optgroup>
              </select>
          </FieldWrapper>) : null }
          <FieldWrapper>
            <label htmlFor="id-title">Title</label>
            <input
              ref={titleRef}
              id="id-title"
              name="title"
              type="text"
              value={props.title}
              onChange={props.handleTitleChange}
              placeholder="Pin's title (optionnal)"
            />
          </FieldWrapper>
          <div className={styles.checkboxWrapper}>
            <button
              type="button"
              className={props.unsafe ? styles.checkedButton : styles.unCheckedButton}
              id="id-unsafe"
              name="unsafe"
              onClick={props.handleUnsafeClick}
            ><AccessibilityText text={props.unsafe ? "Set pin as safe" : "Set pin as unsafe"} /></button>
            <label htmlFor="id-unsafe">Unsafe</label>
          </div>
          <FieldWrapper>
            <label htmlFor="id-caption">Pin description</label>
            <textarea
              ref={captionRef}
              id="id-caption"
              name="caption"
              rows="10"
              value={props.caption}
              onChange={props.handleCaptionChange}
              placeholder="Few words about this pin (optionnal)"
            />
          </FieldWrapper>
          <FieldWrapper>
            <label htmlFor="id-tags">Tags</label>
            <TagInput tags={props.tags} setTags={props.setTags} />
          </FieldWrapper>
          <FieldWrapper>
            <FormErrors
              error={props.error}
            />
          </FieldWrapper>
          <FieldWrapper>
            {(props.showLikePreviousButton && (lastTitle || lastCaption || lastTags)) ? (
              <Button
                secondary={true}
                onClick={setPrevious}
                title="Set title, caption and tags as on previous pin on this board"
              >Like previous</Button>
            ) : null}
          </FieldWrapper>
        </div>
      </form>
    </div>
  )
}


CreatePinForm.propTypes = {
  form: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  rating: PropTypes.number,
  board: PropTypes.string,
  unsafe: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleCaptionChange: PropTypes.func.isRequired,
  handleBoardChange: PropTypes.func,
  handleUnsafeClick: PropTypes.func.isRequired,
  setRating: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  showLikePreviousButton: PropTypes.bool,
  disabledBoardsIDs: PropTypes.arrayOf(
    PropTypes.string
  ),
  boards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  recentBoards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  delete: PropTypes.func,
}


export default CreatePinForm
