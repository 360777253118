import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'
import styles from '../createBoardButton/createBoardButton.module.css'
import RoundCross from '../roundCross/RoundCross'

function CreateFolderButton(props) {
  if (! props.owner) return null
  return (
    <article
      className={styles.create}
    >
      <button
        onClick={() => {
          if (props.private) { 
            modalStatusVar({
              type: modalTypes.CREATE_FOLDER,
              options: { secret: true },
            })
            //console.log('create a private folder', modalStatusVar())
          } else {
            modalStatusVar({ type: modalTypes.CREATE_FOLDER })
            //console.log('create a folder', modalStatusVar())
          }
        }}
      ><RoundCross
          title={props.private ? "Create a private folder" : "Create a folder"}
      /></button>
    </article>
  )
}


CreateFolderButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
}

export default CreateFolderButton
