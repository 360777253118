import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'
import TagInput from '../tagInput/TagInput'
import ManageBoardSharedWith from 'components/manageBoardSharedWith/ManageBoardSharedWith'
import HelpText from '../helpText/HelpText'

function UpdateBoardForm(props) {
  //console.log('UpdateBoardForm', props)
  
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-title">Title <FormRequiredField /></label>
        <input
          id="id-title"
          name="title"
          type="text"
          value={props.title}
          onChange={props.handleTitleChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-policie">Policie <FormRequiredField /></label>
          <select
            id="id-policie"
            name="policie"
            value={props.policie}
            onChange={props.handlePolicieChange}
            required
          >
            <option
              key="private"
              value="private"
            >Private</option>
            <option
              key="public"
              value="public"
            >Public</option>
          </select>
      </FieldWrapper>
      {props.policie === "private" ? <ManageBoardSharedWith boardID={props.id} /> : null}
      <FieldWrapper>
        <label htmlFor="id-folder">Folder </label>
          <select
            id="id-folder"
            name="folder"
            value={props.folder}
            onChange={props.handleFolderChange}
          >
            <option
              key={null}
              value=""
            >   </option>
            {props.folders.map((folder) => (
              <option
                key={folder.id}
                value={folder.id}
              >{folder.name}</option>
            ))}
          </select>
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-caption">Caption</label>
        <textarea
          id="id-caption"
          name="caption"
          rows="5"
          value={props.caption}
          onChange={props.handleCaptionChange}
          placeholder="Few words about this board (optional)"
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-pin-default-description">Default description for pins</label>
        <textarea
          id="id-pin-default-description"
          name="pin-default-description"
          rows="5"
          value={props.pinDefaultDescription}
          onChange={props.handlePinDefaultDescriptionChange}
          placeholder="Default description when adding pins to this board."
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-tags">Default tags for pins</label>
        <TagInput tags={props.pinDefaultTags} setTags={props.setPinDefaultTags} />
      </FieldWrapper>



      <FieldWrapper>
        <label htmlFor="id-id">Board GUID</label>
        <input
          id="id-id"
          name="id"
          type="text"
          value={props.id}
          disabled
        />
        <HelpText
          text="Not editable. Copy this GUID for use in other applications."
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>
    </form>
  )
}


UpdateBoardForm.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  pinDefaultDescription: PropTypes.string,
  pinDefaultTags: PropTypes.array,
  policie: PropTypes.string,
  folder: PropTypes.string,
  folders: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleCaptionChange: PropTypes.func.isRequired,
  handlePinDefaultDescriptionChange: PropTypes.func.isRequired,
  handlePolicieChange: PropTypes.func.isRequired,
  handleFolderChange: PropTypes.func.isRequired,
  setPinDefaultTags: PropTypes.func.isRequired,
}


export default UpdateBoardForm
