import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'
import styles from '../createBoardButton/createBoardButton.module.css'
import RoundCross from '../roundCross/RoundCross'

function CreatePinButton(props) {
  return (
    <article
      className={styles.create}
    >
      <button
        onClick={() => {
          modalStatusVar({
            type: modalTypes.CHOOSE_PIN_ORIGIN, 
            options: { targetBoardID: props.boardID },
          })
          //console.log('create a pin', modalStatusVar())
        }}
      ><RoundCross
          title="Add a pin"
      /></button>
    </article>
  )
}


CreatePinButton.propTypes = {
  owner: PropTypes.bool,
  boardID: PropTypes.string,
}

export default CreatePinButton
