import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, gql } from '@apollo/client'

import styles from './tagInput.module.css'


const TAG_SUGGESTIONS_QUERY = gql`
  query TagSuggestionsQuery($token: String!, $actualTags: [String]!, $first: Int) {
    tagSuggestions(token: $token, actualTags: $actualTags, first: $first) {
      name
      count
    }
  }
`

function parseTagsString(tagsString) {
  let tags = []
  const segments = tagsString.replaceAll('#', ' ').split(' ')
  for (let segment of segments) {
    const tag = trimHash(segment)
    if (tag) {
      tags.push(tag)
    }
  }

  return tags
} 

function trimHash(str) {
  let tag = str.trim()
  if (tag.startsWith('#')) {
    tag = tag.slice(1)
  }

  return tag
}

function getToken(tagsString, selectionStart) {
  const lastChar = tagsString.substring(selectionStart - 1, selectionStart)
  if (lastChar === ' ' || lastChar === '#') return ''
  const segments = parseTagsString(tagsString.substring(0, selectionStart))
  return segments[segments.length - 1]
}

function TagInput({ tags, setTags }) {
  const [tagsString, setTagsString] = useState('')
  const [getTagSuggestions, { error, data }] = useLazyQuery(TAG_SUGGESTIONS_QUERY)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [selected, setSelected] = useState(null)
  const inputRef = useRef(null)

  const completeSuggestion = (index) => {
    // complete current input with selected suggestion, without validation
    // or adding trailing space (so user can extend suggestion)
    const tag = data.tagSuggestions[index].name
    const current = inputRef.current
    const token = getToken(tagsString, current.selectionStart)
    let tagEnd = tag.slice(token.length)
    /*
      * Document.execCommand, albeit deprecated, allows us to keep browser undo history working
      */
    document.execCommand("insertText", false, tagEnd)
  }

  const selectSuggestion = (index) => {
    const tag = data.tagSuggestions[index].name
    const tags = parseTagsString(tagsString)

    if (tags.length === 1) {
      // Only one tag in input, we just add it to tags list
      handleAddTags(tag)
    } else {
      // We are in a complex tags string, we add new tag to it
      // and let user validate later
      console.log('tags', tags)
      console.log('tag', tag)
      const current = inputRef.current
      const token = getToken(tagsString, current.selectionStart)
      let tagEnd = tag.slice(token.length)
      const nextChar = tagsString.substring(current.selectionEnd, current.selectionEnd + 1)
      if (nextChar !== ' ') {
        tagEnd += ' ' // we add space at tag end if it's not already followed by one
      }
      /*
       * Document.execCommand, albeit deprecated, allows us to keep browser undo history working
       */
      document.execCommand("insertText", false, tagEnd)
      if (nextChar === ' ') {
        /*
         * space was not added by insertText,
         * so we manually move cursor after existing space.
         * if nextChar is newLine we don't change position.
         */
        current.selectionStart = current.selectionEnd = current.selectionEnd + 1
      }

      /*
       * Modifying tagsString makes cursor jumping at the end of input
       * when we change something in the middle of string
       * and also break browser undo history
       */
      /*
      const stringStart = tagsString.slice(0, endIndex)
      const stringEnd = tagsString.slice(endIndex)
      if (!stringEnd.startsWith(' ')) {
        tagEnd += ' ' // always separate new tag with one space
      }
      setTagsString(stringStart + tagEnd + stringEnd)
      const newCursorIndex = stringStart.length + tagEnd.length
      console.log('newCursorIndex', newCursorIndex)
      inputRef.current.selectionStart = inputRef.current.selectionEnd = newCursorIndex
      console.log('real index', inputRef.current.selectionStart)
      */
    }
    setShowSuggestions(false)
  }


  const handleTagsStringChange = (e) => {
    //console.log('handleTagsStringChange', e.target.value)
    const newValue = e.target.value
    const token = getToken(newValue, e.target.selectionStart)

   
    if (token) {
      //console.log('token', token, token.length)
      let newTags = parseTagsString(newValue)

      // we don't send token in actualTags
      const index = newTags.indexOf(token)
      if (index !== -1) {
        newTags.splice(index, 1)
      }

      const actualTags = [...tags, ...newTags]
      // We start suggestions request
      getTagSuggestions({
        variables: { token, actualTags, first: 5 },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
          setSuggestions(data.tagSuggestions)
          if (data.tagSuggestions.length > 0) {
            setShowSuggestions(true)
          } else {
            setShowSuggestions(false)
          }
        }
      })
      setSelected(null)
    } else {
      // We reset suggestions
      setShowSuggestions(false)
    }
    setTagsString(newValue)
  }

  const handleKeyDown = (e) => {
    /* we don't handle shortcuts if input hasn't focus */
    if (document.activeElement !== inputRef.current) return

    /* 
     * We stop propagation to avoid over components shortcuts handler to trigger
     * (like underlying pin navigation
     */
    e.stopPropagation()


    if (e.key === 'Enter' && (!showSuggestions || selected === null)) {
      // If we have no suggestions and we press enter,
      // Save current tags string
      e.preventDefault()
      handleAddTags(tagsString)
      setShowSuggestions(false)
    }

    /* we don't handle next shortcuts if we have no suggestions */
    if (!showSuggestions) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (selected !== null && selected < suggestions.length - 1) setSelected(selected + 1)
      else setSelected(0)
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (selected !== null && selected > 0) setSelected(selected - 1)
      else setSelected(suggestions.length - 1)
    }
    if (e.key === 'ArrowRight') {
      if (selected !== null) {
        e.preventDefault()
        completeSuggestion(selected)
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      if (selected !== null) selectSuggestion(selected)
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      setSelected(null)
      setShowSuggestions(false)
    }
  }

  const handleAddTags = (tagsString) => {
    setTagsString('') // we reset tag string
    let nextTags = tags.slice()
    const tagsToAdd = parseTagsString(tagsString)
    for (let tag of tagsToAdd) {
      if (!tags.includes(tag)) {
        nextTags.push(tag)
      }
    }
    setTags(nextTags)
  }

  const handleRemoveTag = (tag) => {
    const index = tags.indexOf(tag)
    if (index === -1) return
    let nextTags = tags.slice()
    nextTags.splice(index, 1)
    setTags(nextTags)
  }

  const handleBlur = () => {
    setSuggestions([])
    setShowSuggestions(false)
  }
  
  
  if (error) console.error(error)

  return (
    <div>
      <div className={styles.tagsList}>
        {tags.map(tag => 
          <div
            key={tag}
            className={styles.tagItem}
          >
            #{tag}
            <button
              className={styles.removeButton}
              title="Remove this tag"
              onClick={(e) => handleRemoveTag(tag)}
            >×</button>
          </div>
        )}
      </div>
      <input
        ref={inputRef}
        id="id-tags"
        value={tagsString}
        name="tags"
        type="text"
        onChange={handleTagsStringChange}
        placeholder="Enter tags"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <div className={StyleSheet.suggestionsWrapper}>
        { showSuggestions ?
          (<ul 
            className={styles.suggestions}
          >
            {suggestions.map( (suggestion, index) =>
              <li
                key={suggestion.name}
                className={selected === index ? styles.selected : null}
                onMouseDown={(e) => { 
                  /* We use onMouseDown because it triggers before blur
                   * we prevent default to avoid loosing textarea focus
                   */
                  e.preventDefault()
                  e.stopPropagation()
                  selectSuggestion(index)
                }}
              >
                <div>#{suggestion.name}</div>
                <div className={styles.count}>{suggestion.count} pins</div>
              </li>
            )}
            {/* loading ? <li>Loading...</li> : null // removed it makes blinking */}
          </ul>) : null }
      </div>
    </div>
  )
}


TagInput.propTypes = {
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired,
}

export default TagInput