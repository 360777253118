import PropTypes from 'prop-types'
import React, { 
  useEffect, 
  useRef, 
  useState,
  useCallback,
  useMemo,
} from 'react'

import { Children } from 'react'

import styles from './columnGrid.module.css'

import ColumnItem from '../columnItem/ColumnItem'



function ColumnGrid({ children, itemWidth, gutter }) {

  //console.log('ColumnGrid', children, itemWidth, gutter)
  //console.log(Children.toArray(children), 'children')

  const ref = useRef(null)
  let [grid, setGrid] = useState({})
  let [positions, setPositions] = useState([])
  let [containerMinHeight, setContainerMinHeight] = useState(0)
  let arrayChildren = useMemo(() => Children.toArray(children), [children])

  const computeGrid = useCallback(() => {
    /* Must run at first render and on window resize */
    //console.log('computeGrid')

    // We compute number of columns from container width
    const parentWidth = ref.current.clientWidth
    const colsNumber = Math.floor(
      (parentWidth + gutter) / (itemWidth + gutter)
    )

    // We compute total width of columns + gutters to center them in container
    const leftMargin = Math.floor(
      (parentWidth - ((itemWidth * colsNumber) + (gutter * (colsNumber - 1)))) / 2
    )

    // We initiate each column Y position to gutter to get top gutter
    let colsY = Array(colsNumber).fill(gutter)

    // We set X position for each column
    let colsX = []
    let n = leftMargin
    for (let i=0; i < colsNumber; i++) {
      colsX.push(n)
      n += (gutter + itemWidth)
    }
    
    // We save everything in state
    setGrid({
      X: colsX,
      Y: colsY,
    })
  }, [gutter, itemWidth])

  const computePositions = useCallback(() => {
    /* Must run each time gridsize or children changes */
    //console.log('compute positions')
    let newPositions = []
    if (! grid.X || ! grid.Y) return // grid is not set yet
    let colsHeights = [...grid.Y]

    Children.map(arrayChildren, (child, index) => {
      let height = child.props.height
      if (! height) {
        console.error('Each item in ColumnGrid must have a "height" prop with it\'s height in pixel.')
        return
      }

      // We add item to less tall column
      let position = {}
      let col = colsHeights.indexOf(Math.min(...colsHeights))
      position.X = grid.X[col]
      position.Y = colsHeights[col]
      colsHeights[col] += height + gutter
      newPositions.push(position)
    })

    // We store positions
    setPositions(newPositions)
    
    // We set container min height
    setContainerMinHeight(Math.max(...colsHeights))
  }, [arrayChildren, grid.X, grid.Y, gutter])


  useEffect(() => {
    // we compute grid at initial render
    computeGrid()
    // we compute grid at each window resize
    window.addEventListener('resize', computeGrid) 
    return () => window.removeEventListener('resize', computeGrid)
  }, [computeGrid])

  useEffect(() => {
    // We compute position at each changement on arryChildren, or grid
    computePositions()
  }, [computePositions])


  return (
    <ul
      className={styles.grid}
      ref={ref}
      style={{minHeight: (containerMinHeight + 'px')}}
    >
      {Children.map(arrayChildren, (child, index) => {
        //console.log('display child', index)
        return (
          <ColumnItem
            translateX={positions[index] ? positions[index].X : 0}
            translateY={positions[index] ? positions[index].Y : 0}
            display={positions[index] ? true : false}
          >{child}</ColumnItem>
        )
      })}
    </ul>
  )
}

ColumnGrid.propTypes = {
  itemWidth: PropTypes.number.isRequired,
  gutter: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
}



export default ColumnGrid
