import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useQuery, useLazyQuery, gql } from '@apollo/client'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import { AUTHENTICATED_QUERY } from '../app/App'
import Button from '../button/Button'


const PIN_CLONES_QUERY = gql`
  query PinClonesQuery($pinID: ID!) {
  me {
    clones(pin: $pinID) {
      id
      sha1
      title
      caption
    }
  }
}
`

function PinItButton(props) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data } = useQuery(AUTHENTICATED_QUERY)
  const [getPinClones] = useLazyQuery(PIN_CLONES_QUERY)
  const title = props.title || 'Pin it'
  return (
    <Button
      title={title}
      primary={true}
      className={props.className}
      onClick={() => {
        if ( data && data.me) { // we have a user
          // If user is owner, we redirect to addPinToBoard
          if (props.owner) {
            modalStatusVar({
              type: modalTypes.ADD_PIN_TO_BOARD,
              options: { pinID: props.pinID }
            })
          } else { // we search clones for user
            getPinClones({
              variables: { pinID: props.pinID },
              fetchPolicy: 'network-only',
              onCompleted: (data) => {
                if (data.me.clones.length > 0) {
                  // we have clone, redirect to addPinToBoard
                  modalStatusVar({
                    type: modalTypes.ADD_PIN_TO_BOARD,
                    options: { pinID: data.me.clones[0].id }
                  })
                } else { // no clones, show duplicatePin
                  modalStatusVar({
                    type: modalTypes.DUPLICATE_PIN,
                    options: { pinID: props.pinID }
                  })
                }
              },
              onError: (error) => {
                console.error(error)
              }
            })
          }
        } else { // user is not authenticated, we redirect to login page
          return navigate(`/login/?next=${pathname}`)
        }
      }}
    >{title}</Button>
  )
}

PinItButton.propTypes = {
  pinID: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default PinItButton
