import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useReactiveVar } from '@apollo/client'

import { modalStatusVar  } from 'modal'
import styles from './modal.module.css'


function withModal(WrappedComponent) {  
  return (props) => {
    const modalStatus = useReactiveVar(modalStatusVar)

    useEffect(() => {
      window.addEventListener('keydown', keydown)
      return () => window.removeEventListener('keydown', keydown)
    })
    
    //console.log('withModal', props)

    function closeIfClosable(e) {
      if (props.closable) {
        modalStatusVar(null)
      }
      e.stopPropagation()
    }

    function closeModal(e) {
      if (e) e.stopPropagation()
      modalStatusVar(null)
    }

    function keydown(e) {
      if (e.key === 'Escape') {
        closeModal(e)
      }
    }

    if (props.type !== modalStatus?.type) return null
      
      // we remove extra_props
    const { extra_props, ...rest } = props

    return (
      <div
        className={styles.overlay}
        onClick={closeIfClosable}
      >
        <WrappedComponent
          closeModal={closeModal}
          modalOptions={modalStatus?.options}
          {...rest}
        />
      </div>
    )
  }
}

withModal.propTypes = {
  id: PropTypes.string.isRequired,
  closable: PropTypes.bool,
}

export default withModal

