import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'
import withModal from '../../hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  removeItemFromEdges,
  deleteField,
} from '../../helpers/GraphqlHelpers'

import styles from '../addPinToBoard/addPinToBoard.module.css'

const REMOVE_PIN_FROM_BOARD_QUERY = gql`
query RemovePinFromBoardQuery(
  $pinID: ID!,
  $boardID: ID!,
  $minWidth: Int,
  $format: String!
) {
  board: node(id: $boardID) {
    __typename
    ... on Board {
      id
      title
    }
  }
  pin: node(id: $pinID) {
    __typename
    ... on Pin {
      id
      boards { totalCount }
      preview(minWidth: $minWidth, format: $format)
    }
  }
}
`
const REMOVE_PIN_FROM_BOARD = gql`
  mutation RemovePinFromBoard($pinID: ID!, $boardID: ID!) {
    removePinFromBoard(pin: $pinID, board: $boardID) {
      __typename
      id
      node { id }
    }
  }
`


function RemovePinFromBoard(props) {
  const navigate = useNavigate()
  const { pinID, boardID, redirectTo } = props.modalOptions
  
  const getInitialDataStatus = useQuery(REMOVE_PIN_FROM_BOARD_QUERY, {
    variables: {
      pinID,
      boardID,
      minWidth: 650,
      format: "jpeg",
    },
    fetchPolicy: 'cache-and-network',
  })


  const [removePinFromBoard, removePinFromBoardStatus] = useMutation(REMOVE_PIN_FROM_BOARD, {
    update: (cache, { data: { removePinFromBoard } }) => {

      function removePinEdgeFromCache(cachedEdges, { readField }) {
        return removeItemFromEdges(cachedEdges, readField, removePinFromBoard.id)
      }
      
      // We remove pinEdge from it's board
      cache.modify({
        id: `Board:${boardID}`,
        fields: {
          pins: removePinEdgeFromCache,
          // we delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })

      // We update pin boards
      cache.modify({
        id: `Pin:${pinID}`,
        fields: {
          boards: deleteField,
        }
      })

      const data = cache.readQuery({
        query: gql`
          query BoardCoverQuery($boardID: ID!) {
            node(id: $boardID) {
              id
              cover1
              cover2
              cover3
              cover4
              cover5
            }
          }
        `,
        variables: {
          boardID: boardID,
        }
      })

      const covers = ['cover1', 'cover2', 'cover3', 'cover4', 'cover5']

      // TODO will work only when we will get pinID as covers instead of previews urls
      for (let cover of covers) {
        if (data && data.node[cover] === pinID) {
          cache.modify({
            id: `Board:${boardID}`,
            fields: {
              [cover](existingCover, { DELETE }) {
                return DELETE
              }
            }
          })
        }
      }
    },
    onCompleted: (data) => {
      //console.log('removed from board', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    removePinFromBoard({ variables: { pinID, boardID: boardID }})

    /*
     * We must redirect if we are in pin's removed board.
     * We can't access params to check it because modal is outside
     * react-router tree, so we need to get link from above.
     */
    if (redirectTo) {
      navigate(redirectTo)
    }
  }

  //console.log('RemovePinFromBoard', props, getInitialDataStatus)

  if (getInitialDataStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getInitialDataStatus.error) {
    console.error(getInitialDataStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  const board = getInitialDataStatus.data.board
  const pin = getInitialDataStatus.data.pin
  const remainingBoardsCount = pin.boards.totalCount - 1

  return (
    <ModalSection>
      <ModalHeader
        title="Unpin it"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <div className={styles.preview}><img 
          src={pin.preview}
          alt={pin.caption}
        /></div>
        <h6>Are you sure you want to unpin this pin from board "{board.title}"?</h6>
        <p><em>Pin won't be deleted, you'll still find it in "My pins"{remainingBoardsCount >= 1 ? 
          ` and ${remainingBoardsCount} other board${remainingBoardsCount > 1 ? 's' : ''}` : ''
        }.</em></p>
      </ModalContent>
      <ModalFooter>
        {removePinFromBoardStatus.loading && <Spinner message="Unpining pin..." />}
        <Button
          onClick={handleSubmit}
          primary={true}
        >Unpin</Button>
      </ModalFooter>
    </ModalSection>

  )
}

RemovePinFromBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    pinID: PropTypes.string.isRequired,
    boardID: PropTypes.string.isRequired,
    redirectTo: PropTypes.string,
  })
}

export default withModal(RemovePinFromBoard)
