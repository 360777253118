import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'


import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreateBoardForm from '../createBoardForm/CreateBoardForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import { 
  deleteField,
  createItemRef,
  addItemToEdges,
} from 'helpers/GraphqlHelpers'

const CREATE_BOARD_FORM = 'CREATE_BOARD_FORM'

const GET_MY_FOLDERS_QUERY = gql`
query MyFolders {
  me {
    id
    folders {
      edges {
        id
        name
      }
    }
  }
}
`

const CREATE_BOARD_MUTATION = gql`
mutation AddBoard(
  $title: String!,
  $folder: ID,
  $caption: String,
  $secret: Boolean,
) {
  addBoard(
    title: $title,
    folder: $folder,
    caption: $caption,
    secret: $secret
  ) {
    __typename
    id
    slug
    createdAt
    title
    caption
    pinDefaultDescription
    secret
    user { id }
    folder { id }
  }
}
`

export const NEW_BOARD_FRAGMENT = gql`
  fragment NewBoard on Board {
    id
    slug
    createdAt
    title
    caption
    pinDefaultDescription
    secret
    user
  }
`


function CreateBoard(props) {
  const [title, setTitle] = useState('')
  const [folder, setFolder] = useState('')
  const [folders, setFolders] = useState([])
  const [caption, setCaption] = useState('')

  useQuery(GET_MY_FOLDERS_QUERY, {
    onCompleted: ({ me }) => {
      let defaultFolderID = props.modalOptions && props.modalOptions.folderID ?
        props.modalOptions.folderID : ''
      setFolder(defaultFolderID)
      setFolders(me.folders.edges)
    }
  })

  const [createBoard, { loading, error }] = useMutation(CREATE_BOARD_MUTATION, {
    update: (cache, { data: { addBoard } }) => {

      const newBoardRef = createItemRef(cache, addBoard, NEW_BOARD_FRAGMENT)
      
      function addBoardToCache(cachedBoards) {
        return addItemToEdges(cachedBoards, newBoardRef) 
      }

      // We add new board to user boards
      cache.modify({
        id: cache.identify(addBoard.user),
        fields: {
          boards: addBoardToCache,
        }
      })

      // If we have a folder, we add new board to folder boards
      if (addBoard.folder) {
        cache.modify({
          id: cache.identify(addBoard.folder),
          fields: {
            boards: addBoardToCache,
            cover1: deleteField,
            cover2: deleteField,
            cover3: deleteField,
            cover4: deleteField,
            cover5: deleteField,
          }
        })
      }
    },
    onCompleted: (data) => {
      //console.log('created board', data)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })

  let secret = props.modalOptions && props.modalOptions.secret ? 
    props.modalOptions.secret : false


  const handleSubmit = (e) => {
    e.preventDefault()
    createBoard({ variables: {
      title, folder, caption, secret
    }})
  }

  //console.log('CreateBoard', props)
  return (
    <ModalSection>
      <ModalHeader
        title={secret ? "Create a private board" : "Create a board"}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreateBoardForm
          form={CREATE_BOARD_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleFolderChange={(e) => setFolder(e.target.value)}
          handleCaptionChange={(e) => setCaption(e.target.value)}
          title={title}
          folder={folder}
          folders={folders}
          caption={caption}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Creating board..." />}
        <Submit
          primary={true}
          form={CREATE_BOARD_FORM}
          value={secret ? "Create private board" : "Create board"}
        />
      </ModalFooter>
    </ModalSection>
  )
}

CreateBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(CreateBoard)
