import PropTypes from 'prop-types'
import {
  useQuery,
  gql,
} from '@apollo/client'
import { Link } from 'react-router-dom'

import styles from './userShortDetail.module.css'

import { getUserNames } from '../userDetail/UserDetail'


import Spinner from '../spinner/Spinner'
import UserWebsite from '../userWebsite/UserWebsite'
import UserAvatar from '../userAvatar/UserAvatar'

export const USER_ABSTRACT_QUERY = gql`
  query UserShortDetailQuery($username: String!) {
    me {
      id
    }
    user(username: $username) {
      id
      username
      lastName
      firstName
      websiteLink
    }
  }
`

function UserShortDetail(props) {

  const { loading, data, error } = useQuery(USER_ABSTRACT_QUERY, {
    variables: { 
      username: props.username,
    },
  })

  if (loading) return <Spinner />
  if (error) console.error(error)

  //console.log('UserShortDetail', data)

  let userHomeLink = `/${props.username}/`

  return (
    <div className={styles.userShortDetail}>
      <div className={styles.contentWrapper}> 
        <div className={styles.userInfo}>
          <Link 
            to={userHomeLink}
          ><UserAvatar
            username={data.user.username}
            minSize={40}
          /></Link>
          <div className={styles.nameWrapper}>
            <h2><Link
              to={userHomeLink}
            >{getUserNames(data.user)}</Link></h2>
            <UserWebsite websiteLink={data.user.websiteLink} />
          </div>
        </div>
        <div className={styles.profileLink}>
          {props.children}
        </div>
      </div>
    </div>
  )
}


UserShortDetail.propTypes = {
  username: PropTypes.string.isRequired,
}

export default UserShortDetail

