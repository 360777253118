import moment from 'moment'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './pinDetailUser.module.css'

import { USER_ABSTRACT_QUERY } from '../userShortDetail/UserShortDetail'
import { getUserNames } from '../userDetail/UserDetail'
import Spinner from '../spinner/Spinner'
import UserAvatar from '../userAvatar/UserAvatar'

function PinDetailUser(props) {

  const { loading, data, error } = useQuery(USER_ABSTRACT_QUERY, {
    variables: { 
      username: props.username,
    },
  })
  
  // TODO move this to client only graphql field
  let userHomeLink = `/${props.username}/`

  if (loading) return <Spinner />
  if (error) console.error(error)

  return (
    <article className={styles.user}>
      <Link 
        to={userHomeLink}
      ><UserAvatar
        username={data.user.username}
        minSize={60}
      /></Link>
      <p>
        <Link 
          to={userHomeLink}
        >
          <span>Added by</span>
          <br />
          <strong> {getUserNames(data.user)} </strong>
        </Link>
        <span className={styles.date}>• {moment(props.createdAt, 'x').fromNow()}</span>
      </p>
    </article>
  )

}


PinDetailUser.propTypes = {
  username: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
}

export default PinDetailUser
