import React, { useEffect } from 'react'

import styles from './modalSection.module.css'
import PropTypes from 'prop-types'


function ModalSection(props) {
  function getClassNames() {
    const defaultClass = props.max ? styles.maxModalSection : styles.modalSection
    let classes = [defaultClass]

    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  useEffect(() => {
    /*
     * We stop propagation to avoid events bubbling
     * to Components under modal, like PinDetail,
     * and unwanted keyboard navigation.
     * We set event listener on document rather than window
     * to capture events before they reach window
     * (PinDetail navigation is on window)
     */
    const stopPropagation = (e) => {
      //console.log('ModalSection stop propagation')
      e.stopPropagation()
    }
    
    document.addEventListener('keydown', stopPropagation)
    return () => document.removeEventListener('keydown', stopPropagation)
  })
  
  return (
    <div className={styles.modalMarginWrapper}>
      <section
        className={getClassNames()}
      >
        {props.children}
      </section>
    </div>
  )
}

ModalSection.propTypes = {
  max: PropTypes.bool,
  className: PropTypes.string,
}

export default ModalSection
