import React from 'react'
import Header from 'components/header/Header'
import { gql } from '@apollo/client'
import { 
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'

import styles from './app.module.css'

import UserRootLayout from '../userRootLayout/UserRootLayout'
import Login from '../login/Login'
import Signup from '../signup/Signup'
import UserProfile from '../userProfile/UserProfile'
import StartPasswordReset from '../startPasswordReset/StartPasswordReset'
import PasswordReset from '../passwordReset/PasswordReset'
import VerifieEmail from '../verifieEmail/VerifieEmail'
import Modal from '../modal/Modal'
import LastPins from '../lastPins/LastPins'
import NotFound from '../notFound/NotFound'
import UserFolders from '../userFolders/UserFolders'
import FolderDetail from '../folderDetail/FolderDetail'
import UserBoards from '../userBoards/UserBoards'
import BoardDetail from '../boardDetail/BoardDetail'
import UserPins from '../userPins/UserPins'
import PinDetail from '../pinDetail/PinDetail'
import Lightbox from '../lightbox/Lightbox'
import Search from '../search/Search'
import Scan from '../scan/Scan'


export const AUTHENTICATED_QUERY = gql`
  query AuthenticatedQuery {
    me {
      id
      username
    }
  }
`

function App(props) {
  //console.log('App', props)

  return (
    <section
      role="main"
      className={styles.main}
    >
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/start_password_reset" element={<StartPasswordReset />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route path="/verifie_email" element={<VerifieEmail />} />
        <Route path="/scan/" element={<Scan />} end />
        <Route path="/search/" element={<Search />} end />
        <Route path="/search/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/search/pins/:pinID/lightbox" element={<Lightbox />} end />
        <Route path="/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/:username/pins/:pinID/lightbox" element={<Lightbox />} end />
        <Route path="/:username/boards/:boardSlug/" element={<BoardDetail />} end />
        <Route path="/:username/boards/:boardSlug/pins/:pinID/" element={<PinDetail />} end />
        <Route path="/:username/boards/:boardSlug/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/folders/:folderSlug/" element={<FolderDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/" element={<BoardDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/pins/:pinID" element={<PinDetail />} end />
        <Route path="/:username/folders/:folderSlug/boards/:boardSlug/pins/:pinID/lightbox/" element={<Lightbox />} end />
        <Route path="/:username/" element={<UserRootLayout />} >
          <Route index element={<UserBoards />} end />
          <Route path="/:username/folders/" element={<UserFolders />} />
          <Route path="/:username/pins/" element={<UserPins />} end />
        </Route>
        <Route path="/" element={<LastPins />} end />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Modal />
    </section>
  )
}


function AppRoot(props) {
  return (
    <Router>
      <App />
    </Router>
  )
}

export default AppRoot
