import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormRequiredField from '../formRequiredField/FormRequiredField'

function FormRequiredFields() {
  return (
    <FieldWrapper>
      <FormRequiredField /> Required fields</FieldWrapper>)
}

export default FormRequiredFields
