import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import UpdateEmailForm from '../updateEmailForm/UpdateEmailForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'

const UPDATE_EMAIL_FORM = 'UPDATE_EMAIL_FORM'

const UPDATE_EMAIL_MUTATION = gql`
  mutation UpdateUserEmail(
    $newEmail: String!
    $password: String!
  ) {
    updateUserEmail(
      newEmail: $newEmail,
      password: $password,
    ) {
      id
      email
      emailVerified
    }
  }
`

const MY_EMAIL_QUERY = gql`
  query MyEmail {
    me {
      id
      email
      emailVerified
    }
  }
`


function UpdateEmail(props) {
  const [newEmail, setNewEmail] = useState('')
  const [password, setPassword] = useState('')

  const getMyEmailStatus = useQuery(MY_EMAIL_QUERY, {
    onCompleted: ({ me }) =>
      setNewEmail(me.email)
  })

  const [updateEmail, { loading, error }] = useMutation(UPDATE_EMAIL_MUTATION, {
    onCompleted: () => props.closeModal(),
    onError: (e) => console.error(e),
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    updateEmail({ variables: { newEmail, password }})
  }


  if (getMyEmailStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getMyEmailStatus.error) {
    console.error(getMyEmailStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Change my email"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <UpdateEmailForm
          form={UPDATE_EMAIL_FORM}
          onSubmit={handleSubmit}
          handleNewEmailChange={(e) => setNewEmail(e.target.value)}
          handlePasswordChange={(e) => setPassword(e.target.value)}
          newEmail={newEmail}
          password={password}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Updating email..." />}
        <Submit
          primary={true}
          form={UPDATE_EMAIL_FORM}
          value="Save email"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdateEmail.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdateEmail)


