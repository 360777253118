import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatFileSize } from 'helpers/CommonHelpers'
import styles from '../pinUploader/pinUploader.module.css'

function PinUploaderItem(props) {

  const [src, setSrc] = useState(null)

  useEffect(() => {
    let reader = new FileReader()
    reader.onload = (e) => {
      setSrc(reader.result)
    }
    reader.readAsDataURL(props.file)
  }, [props.file])

  return (
    <div
      className={styles.uploaderItem}
      title={`filename: ${props.file.name}\n weight: ${formatFileSize(props.file.size)}`}
    >
      <div>
        <button
          className={styles.overlay}
          title="Don't upload this picture"
          onClick={props.remove}
        >×</button>
        <img
          src={src}
          alt=""
        />
      </div>
    </div>
  )
}

PinUploaderItem.propTypes = {
  remove: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
}


export default PinUploaderItem

