import PropTypes from 'prop-types'

import UpdateBoardButton from '../updateBoardButton/UpdateBoardButton'
import RouterLinkButton from '../routerLinkButton/RouterLinkButton'


function BoardButton(props) {

  if (props.owner) return (
    <UpdateBoardButton
      owner={props.owner}
      className={props.className}
      boardID={props.boardID}
    />
  )
  return (
    <RouterLinkButton
      value="View"
      to={props.link}
      title="View board"
      primary={true}
      className={props.className}
    />
  )
}

BoardButton.propTypes = {
  boardID: PropTypes.string,
  owner: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default BoardButton
