
import PropTypes from 'prop-types'

import CreateFolderButton from '../createFolderButton/CreateFolderButton'
import FolderAbstract from '../folderAbstract/FolderAbstract'


function FoldersList(props) {
  //console.log('FoldersList', props)
  return (
    <div>
      <CreateFolderButton
        owner={props.owner}
        private={props.private}
      />
      {props.folders.map(folder =>
        (<FolderAbstract
          owner={props.owner}
          key={folder.id}
          boardsCount={folder.boards.totalCount}
          {...folder}
        />)
      )}
    </div>
  )
}

FoldersList.propTypes = {
  folders: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
}

export default FoldersList
