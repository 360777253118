import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

import {
  getRecentBoardsIDs,
  setRecentBoard,
  getRecentBoardsList,
  getDisabledBoardsIDs,
  ADD_PIN_TO_BOARD,
} from '../createPin/CreatePin'
import { 
  NEW_BOARD_PIN_EDGE_FRAGMENT,
  MY_BOARDS_FRAGMENT,
} from '../createPin/fragments'
import { 
  deleteField,
  createItemRef,
  addItemToEdges,
} from 'helpers/GraphqlHelpers'

import styles from './addPinToBoard.module.css'

const ADD_PIN_TO_BOARD_FORM = 'ADD_PIN_TO_BOARD_FORM'

const ADD_PIN_TO_BOARD_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query AddPinToBoardQuery($pinID: ID!, $minWidth: Int, $format: String!) {
    ...MyBoardsFragment
    node(id: $pinID) {
      __typename
      ... on Pin {
        id
        caption
        preview(minWidth: $minWidth, format: $format)
        boards {
          totalCount
          edges {
            id
            title
          }
        }
      }
    }
  }

`
function AddPinToBoard(props) {
  const [board, setBoard] = useState('')
  const [recentBoards, setRecentBoards] = useState([])
  const [disabledBoardsIDs, setDisabledBoardsIDs] = useState([])

  let pinID = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null


  const getPinStatus = useQuery(ADD_PIN_TO_BOARD_QUERY, {
    variables: { 
      pinID,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.node)
      // We populate state with initial data
      const disabledBoardsIDs = getDisabledBoardsIDs(data.node.boards.edges)
      const boardID = getDefaultBoardID(data.me.boards.edges, disabledBoardsIDs)
      setBoard(boardID)
      setDisabledBoardsIDs(disabledBoardsIDs)
      setRecentBoards(getRecentBoardsList(data.me.boards.edges))
    }
  })

  function getDefaultBoardID(boards, disabledBoardsIDs) {
    const recentBoardsIDs = getRecentBoardsIDs()

    if (recentBoardsIDs.length > 0) {
      /*
       * If we have recent boards, we return first
       * not disabled board in recent boards list
       */
      for (let id of recentBoardsIDs) {
        if (! disabledBoardsIDs.includes(id)) return id
      }
    }
    if (boards.length > 0) {
      /* 
       * If we have no valid recent boards, we return first
       * not disabled board in all boards list
       */
      for (let board of boards) {
        if (! disabledBoardsIDs.includes(board.id)) return board.id
      }
    }
    return null
  }


  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: (cache, { data: { addPinToBoard } }) => {
      //console.log('addPinToBoard', addPinToBoard)

      // we don't update cache if boardPin hasn't been created
      if (! addPinToBoard.created) return

      const newBoardPinEdgeRef = createItemRef(
        cache, addPinToBoard.boardPinEdge, NEW_BOARD_PIN_EDGE_FRAGMENT
      )

      function addPinBoardEdgeToCache(cachedEdges) {
        return addItemToEdges(cachedEdges, newBoardPinEdgeRef)
      }

      // We update board detail
      cache.modify({
        id: `Board:${board}`,
        fields: {
          pins: addPinBoardEdgeToCache,
          cover1: deleteField,
          cover2: deleteField,
          cover3: deleteField,
          cover4: deleteField,
          cover5: deleteField,
          // we delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })

      // We update pin board's
      cache.modify({
        id: `Pin:${pinID}`,
        fields: {
          boards: deleteField,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('pin added to board', data)
      props.closeModal()
      // don't change context with redirection, it's annoying
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setRecentBoard(board)
    addPinToBoard({variables: { pinID, boardID: board }})
  }


  //console.log('AddPinToBoard props', props)
  //console.log('AddPinToBoard getPinStatus', getPinStatus)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  const myBoards = getPinStatus.data.me.boards.edges
  const pin = getPinStatus.data.node
  return (
    <ModalSection>
      <ModalHeader
        title="Pin it"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <div className={styles.preview}><img 
          src={pin.preview}
          alt={pin.caption}
        /></div>
        <form
          id={ADD_PIN_TO_BOARD_FORM}
          onSubmit={handleSubmit}
        >
          <FormRequiredFields />
          <FieldWrapper>
            <label htmlFor="id-board">Board <FormRequiredField /></label>
              <select
                id="id-board"
                name="board"
                value={board}
                onChange={(e) => setBoard(e.target.value)}
                required
              >
                <optgroup label="Last used boards">
                  {recentBoards.map(board =>
                    <option
                      disabled={disabledBoardsIDs.includes(board.id)} 
                      key={board.id}
                      value={board.id}
                    >{board.title}{board.folder ? ` [${board.folder.name}]` : null}</option>
                  )}
                </optgroup>
                <optgroup label="All boards">
                {myBoards.map(board =>
                  <option
                    disabled={disabledBoardsIDs.includes(board.id)} 
                    key={board.id}
                    value={board.id}
                  >{board.title}{board.folder ? ` [${board.folder.name}]` : null}</option>
                )}
                </optgroup>
              </select>
          </FieldWrapper>
          <FieldWrapper>
            <FormErrors
              error={addPinToBoardStatus.error}
            />
          </FieldWrapper>
        </form>
      </ModalContent>
      <ModalFooter>
        {addPinToBoardStatus.loading && <Spinner message="Adding pin to board..." />}
        <Submit
          primary={true}
          form={ADD_PIN_TO_BOARD_FORM}
          value="Pin it"
        />
      </ModalFooter>
    </ModalSection>
  )
}

AddPinToBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    pinID: PropTypes.string.isRequired,
  }),
}

export default withModal(AddPinToBoard)
