import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from '../pinDetailUser/pinDetailUser.module.css'

import { getUserNames } from '../userDetail/UserDetail'
import Spinner from '../spinner/Spinner'
import UserAvatar from '../userAvatar/UserAvatar'


const PIN_ADDED_VIA_QUERY = gql`
  query PinAddedViaQuery($id: ID!) {
    me {
      id
    }
    node(id: $id) {
      __typename
      ... on Pin {
        id
        user {
          id
          username
          lastName
          firstName
        }
      }
   }
}
`



function PinAddedVia(props) {

  const { loading, data, error } = useQuery(PIN_ADDED_VIA_QUERY, {
    variables: { 
      id: props.addedVia,
    },
  })

  if (loading) return <Spinner />
  if (error) console.error(error)
  
  let user = data.node.user
  let userHomeLink = `/${user.username}/`

  return (
    <article className={styles.user}>
      <Link 
        to={userHomeLink}
      ><UserAvatar
        username={user.username}
        minSize={60}
      /></Link>
      <p>
        <Link 
          to={userHomeLink}
        >
          <span>Added via</span>
          <br />
          <strong> {getUserNames(user)} </strong>
        </Link>
      </p>
    </article>
  )

}


PinAddedVia.propTypes = {
  addedVia: PropTypes.string,
}

export default PinAddedVia
