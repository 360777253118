import PropTypes from 'prop-types'
import styles from './submit.module.css'

function getClassNames(props) {
  let classes = []

  // we add extra classes
  classes.push(styles.button)
  if (props.primary) { classes.push(styles.primary) }
  if (props.className) { classes.push(props.className) }

  return classes.join(" ")
}


function Submit(props) {
  return (
    <input
      className={getClassNames(props)}
      type="submit"
      title={props.title || null}
      value={props.value}
      form={props.form}
      style={props.style}
      disabled={props.disabled || null}
    />
  )
}

Submit.propTypes = {
  value: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  primary: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool,
}

export default Submit
  
