import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import Button from '../button/Button'


function UpdatePinButton(props) {
  if (! props.owner) return null

  return (
    <Button
      title="Edit pin"
      primary={true}
      className={props.className}
      onClick={() => {
        modalStatusVar({
          type: modalTypes.UPDATE_PIN,
          options: { pinID: props.pinID },
        })
        //console.log('update pin', props.pinID)
      }}
    >Edit</Button>
  )
}

UpdatePinButton.propTypes = {
  owner: PropTypes.bool,
  pinID: PropTypes.string,
  className: PropTypes.string,
}

export default UpdatePinButton
