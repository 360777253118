
import { Link } from 'react-router-dom'
import HeaderLinks from '../headerLinks/HeaderLinks'
import HeaderSearch from '../headerSearch/HeaderSearch'

import pinpict1x from './assets/pinpict.1x.png'
import pinpict2x from './assets/pinpict.2x.png'
import styles from './header.module.css'


function Header() {
  return (
    <header
      role="banner"
      className={styles.header}
    >
      <h1
        className={styles.title}
      >
        <Link to="/">
          <img
            src={pinpict1x}
            srcSet={`${pinpict1x} 1x, ${pinpict2x} 2x`}
            alt="PinPict"
          />
        </Link>
      </h1>
      <HeaderSearch />
      <HeaderLinks />
    </header>
  )
}

export default Header
