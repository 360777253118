
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ColumnGrid from '../columnGrid/ColumnGrid'
import PinAbstract from '../pinAbstract/PinAbstract'
import LoadingMore from '../loadingMore/LoadingMore'


const PIN_WIDTH = 236
function LastPinsList(props) {
  //console.log('LastPinsList', props)
  
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    
    if (scrollTop + clientHeight >= scrollHeight) {
      if (props.hasNextPage && ! props.loadingMore) {
        props.loadMore()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })


  return (
    <section className="columned">
      <ColumnGrid
        itemWidth={PIN_WIDTH}
        gutter={14}
      >
        {props.pins.map(pin => {
          let imageHeight = Math.round((pin.node.height / pin.node.width) * PIN_WIDTH)
          let pinHeight = imageHeight  // add footer height if necessary

          let owner = pin.node.user.id === props.visitorID
          
          return (
            <PinAbstract
              key={pin.node.id}
              owner={owner}
              id={pin.node.id}
              imageHeight={imageHeight}
              originalWidth={pin.node.width}
              originalHeight={pin.node.height}
              caption={pin.node.caption}
              rating={pin.node.rating}
              height={pinHeight}
              baseLink={props.baseLink}
              queryString={props.queryString}
            />
          )})}
      </ColumnGrid>
      <LoadingMore
        loadingMore={props.loadingMore}
      />
    </section>
  )
}

LastPinsList.propTypes = {
  pins: PropTypes.array.isRequired,
  visitorID: PropTypes.string,
  hasNextPage: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  baseLink: PropTypes.string.isRequired,
  queryString: PropTypes.string,
}

export default LastPinsList
