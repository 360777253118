
// Delete a field in cache
export function deleteField(_, { DELETE }) {
  return DELETE
}

// Invalidate a field in cache
export function invalidateField(_, { INVALIDATE }) {
  return INVALIDATE
}

// Create a ref from item
export function createItemRef(cache, data, fragment) {
  return cache.writeFragment({
    data,
    fragment,
  })
}

// Create an edge from item ref
export function createItemEdgeNode(itemRef, typename) {
  return {
    __typename: typename,
    node: { itemRef }
  }
}


// Add an item to Edges list
export function addItemToEdges(cachedItems, newItemRef) {
  let edges
  if (! cachedItems.edges) {
    edges = [newItemRef]
  } else {
    edges = [newItemRef, ...cachedItems.edges]
  }
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount + 1,
    edges,
  }
}


// Remove an item from Edges list
export function removeItemFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef)
    )
  }
}


// Add node item to Edges list
export function addNodeToEdges(cachedItems, itemRef, typename) {
  return addItemToEdges(cachedItems, createItemEdgeNode(itemRef, typename))
}

// Remove node item from Edges list
export function removeNodeFromEdges(cachedItems, readField, idToRemove) {
  if (! cachedItems.edges) return
  return {
    ...cachedItems,
    totalCount: cachedItems.totalCount - 1,
    edges: cachedItems.edges.filter(
      itemRef =>  idToRemove !== readField('id', itemRef.node)
    )
  }
}
