import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'


function UpdatePasswordForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-currentPassword">Current password:<FormRequiredField /></label>
        <input
          id="id-currentPassword"
          name="currentPassword"
          type="password"
          maxLength="255"
          value={props.currentPassword}
          onChange={props.handleCurrentPasswordChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-newPassword">New password:<FormRequiredField /></label>
        <input
          id="id-newPassword"
          name="newPassword"
          type="password"
          maxLength="255"
          value={props.newPassword}
          onChange={props.handleNewPasswordChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-newPasswordConfirm">New password confirmation:<FormRequiredField /></label>
        <input
          id="id-newPasswordConfirm"
          name="newPasswordConfirm"
          type="password"
          maxLength="255"
          value={props.newPasswordConfirm}
          onChange={props.handleNewPasswordConfirmChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
          localErrors={props.localErrors}
        />
      </FieldWrapper>
    </form>
  )
}


UpdatePasswordForm.propTypes = {
  form: PropTypes.string.isRequired,
  currentPassword: PropTypes.string,
  newPassword: PropTypes.string,
  newPasswordConfirm: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleCurrentPasswordChange: PropTypes.func.isRequired,
  handleNewPasswordChange: PropTypes.func.isRequired,
  handleNewPasswordConfirmChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  locolErrors: PropTypes.array,
}


export default UpdatePasswordForm
