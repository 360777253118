import PropTypes from 'prop-types'

import styles from './formErrors.module.css'



function FormErrors(props) {

  //console.log('FormErrors', props)

  if (props.localErrors) {
    return (
      <ul
        className={styles.errorsList}
      >
        {props.localErrors.map(error =>
          <li
            key={error}
          >{error}</li>
        )}
      </ul>
    )
  }

  if (props.error && props.error.networkError) {
    return (
      <ul
        className={styles.errorsList}
      >
        <li>A network error occured</li>
      </ul>
    )
  }


  if (props.error && props.error.graphQLErrors) {
    return (
      <ul
        className={styles.errorsList}
      >
        {props.error.graphQLErrors.map(error =>
          <li
            key={error}
          >{error.message}</li>
        )}
      </ul>
    )
  }

  return null
}

FormErrors.propTypes = {
  error: PropTypes.object,
  localErrors: PropTypes.array,
}

export default FormErrors
