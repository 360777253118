import PropTypes from 'prop-types'

import styles from './deleteButton.module.css'

import Button from '../button/Button'

function DeleteButton(props) {
  return (
    <div className={styles.deleteButton}>
      <Button
        title={props.title}
        onClick={props.onClick}
        primary={true}
      >{props.title}</Button>
    </div>
  )
}

DeleteButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default DeleteButton
