import PropTypes from 'prop-types'

import styles from './userWebsite.module.css'


function UserWebsite(props) {
  if (! props.websiteLink) return null
  return (
    <div className={styles.website}>
      <a
        href={props.websiteLink}
        target="_blank"
        rel="noopener noreferrer"
      >{props.websiteLink}</a>
    </div>
  )
}


UserWebsite.propTypes = {
  websiteLink: PropTypes.string
}


export default UserWebsite
