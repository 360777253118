
import PropTypes from 'prop-types'
import {
  gql,
  useQuery,
} from '@apollo/client'
import { 
  humanSize,
  humanExposureTime,
} from '../../helpers/CommonHelpers'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'


import styles from './pinInfo.module.css'

const PIN_INFO_QUERY = gql`
  query PinInfoQuery($pinID: ID!) {
    node(id: $pinID) {
      id
      __typename
      ... on Pin {
        height
        width
        weight
        camera
        lens
        focalLength
        exposureTime
        aperture
        iso
      }
    }
 } 
`

function Exposure({ exposureTime, aperture, iso }) {
  if (!exposureTime && !aperture && !iso) {
    return (<div className={styles.infoGroup + ' ' + styles.exposure}><em>No exposure information</em></div>)
  }
  return (
    <div className={styles.infoGroup + ' ' + styles.exposure}>
      {exposureTime ? (<span>{humanExposureTime(exposureTime)} sec</span>) : null}
      {aperture ? (<span><span className={styles.fnum}>{"\u{1D453}"}</span>/{aperture}</span>) : null}
      {iso ? (<span>ISO {iso}</span>) : null}
    </div>
  )
}

function CameraAndSize({ camera, lens, focalLength, width, height, weight }) {
  return (
    <div className={styles.infoGroup}>
      <div>{camera || (<em>No camera information</em>)}</div>
      <div>{lens || (<em>No lens information</em>)}
      {focalLength ? ' (' + focalLength + ' mm)' : ""}</div>
      <div>{width} × {height} px{"\u2002\u2022\u2002"}{humanSize(weight)}</div>
    </div>
  )
}

function PinInfo({ pinID }) {

  const { loading, data, error } = useQuery(PIN_INFO_QUERY, {
    variables: { pinID }
  })


  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }
  
  const pin = data.node

  return (
    <section className={styles.pinInfo}>
      <div className={styles.wrapper}>
        <Exposure 
          exposureTime={pin.exposureTime}
          aperture={pin.aperture}
          iso={pin.iso}
        />
        <CameraAndSize
          camera={pin.camera}
          lens={pin.lens}
          focalLength={pin.focalLength}
          width={pin.width}
          height={pin.height}
          weight={pin.weight}
        />
      </div>
    </section>
  )
}

export default PinInfo


PinInfo.propTypes = {
  pinID: PropTypes.string.isRequired,
}
