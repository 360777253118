import { useParams, Outlet } from 'react-router-dom'
import UserDetail from '../userDetail/UserDetail'

function UserRootLayout() {
  let { username } = useParams()

  return (
    <div>
      <UserDetail username={username} />
      <Outlet />
    </div>
  )
}

export default UserRootLayout
