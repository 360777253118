import PropTypes from 'prop-types'

import RouterLinkButton from '../routerLinkButton/RouterLinkButton'
import styles from './userProfileLink.module.css'

function UserProfileLink(props) {
  if (props.owner) {
    return (
      <RouterLinkButton
        value="Edit profile"
        to="/profile/"
        className={styles.link}
        title="Edit profile"
        primary={true}
      />
    )
  }
  return null
}

UserProfileLink.propTypes = {
  owner: PropTypes.bool.isRequired,
}

export default UserProfileLink
