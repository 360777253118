import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'
import styles from './createBoardButton.module.css'
import RoundCross from '../roundCross/RoundCross'

function CreateBoardButton(props) {
  if (! props.owner) return null
  //console.log('CreateBoardButton', props)
  return (
    <article
      className={styles.create}
    >
      <button
        onClick={() => {
          modalStatusVar({
            type: modalTypes.CREATE_BOARD,
            options: { secret: props.private, folderID: props.folderID },
          })
        }}
      ><RoundCross
          title={props.private ? "Create a private board" : "Create a board"}
      /></button>
    </article>
  )
}


CreateBoardButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
  folderID: PropTypes.string,
}

export default CreateBoardButton
