import { makeVar } from '@apollo/client'

export const modalStatusVar = makeVar(null)

export const modalTypes = {
  LOGOUT: 'LOGOUT',
  UPLOAD_AVATAR: 'UPLOAD_AVATAR',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',

  CREATE_BOARD: 'CREATE_BOARD',
  UPDATE_BOARD: 'UPDATE_BOARD',
  DELETE_BOARD: 'DELETE_BOARD',

  CREATE_FOLDER: 'CREATE_FOLDER',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  DELETE_FOLDER: 'DELETE_FOLDER',

  CHOOSE_PIN_ORIGIN: 'CHOOSE_PIN_ORIGIN',
  DOWNLOAD_PIN: 'DOWNLOAD_PIN',
  DOWNLOAD_PIN_FROM_SCAN: 'DOWNLOAD_PIN_FROM_SCAN',
  UPLOAD_PIN: 'UPLOAD_PIN',
  CREATE_PIN: 'CREATE_PIN',
  DUPLICATE_PIN: 'DUPLICATE_PIN',
  ADD_PIN_TO_BOARD: 'ADD_PIN_TO_BOARD',
  REMOVE_PIN_FROM_BOARD: 'REMOVE_PIN_FROM_BOARD',
  UPDATE_PIN: 'UPDATE_PIN',
  DELETE_PIN: 'DELETE_PIN',
  PIN_CLONES: 'PIN_CLONES',
}
