import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

function CreateBoardForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-title">Title <FormRequiredField /></label>
        <input
          id="id-title"
          name="title"
          type="text"
          value={props.title}
          onChange={props.handleTitleChange}
          autoFocus
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-folder">Folder </label>
          <select
            id="id-folder"
            name="folder"
            value={props.folder}
            onChange={props.handleFolderChange}
          >
            <option
              key={null}
              value=""
            >   </option>
            {props.folders.map((folder) => (
              <option
                key={folder.id}
                value={folder.id}
              >{folder.name}</option>
            ))}
          </select>
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-caption">Caption</label>
        <textarea
          id="id-caption"
          name="caption"
          rows="10"
          value={props.caption}
          onChange={props.handleCaptionChange}
          placeholder="Few words about this board (optional)"
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>

    </form>
  )
}


CreateBoardForm.propTypes = {
  form: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  folder: PropTypes.string,
  folders: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  handleCaptionChange: PropTypes.func.isRequired,
  handleFolderChange: PropTypes.func.isRequired,
}


export default CreateBoardForm
