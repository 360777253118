import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FormRequiredFields from '../formRequiredFields/FormRequiredFields'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'

function UploadAvatarForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FormRequiredFields />
      <FieldWrapper>
        <label htmlFor="id-file">File <FormRequiredField /></label>
        <input
          id="id-file"
          name="file"
          type="file"
          onChange={props.handleFileChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>
    </form>
  )
}


UploadAvatarForm.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
}


export default UploadAvatarForm
