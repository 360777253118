import React, { useEffect }from 'react'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import Spinner from '../spinner/Spinner'
import useQueryString from '../../hooks/useQueryString'
import FormErrors from '../formErrors/FormErrors'

import { DOWNLOAD_PIN_MUTATION } from '../downloadPin/DownloadPin'


function DownloadPinFromScan(props) {
  const queryString = useQueryString()

  const [downloadPin, { loading, error }] = useMutation(DOWNLOAD_PIN_MUTATION, {
    onCompleted: (data) => {
      //console.log('downloaded pin', data)

      if (data.downloadPinFile.clones) { // we don't add pin
        modalStatusVar({
          type: modalTypes.PIN_CLONES,
          options: { clones: data.downloadPinFile.clones },
        })

        return
      }

      modalStatusVar({
        type: modalTypes.CREATE_PIN,
        options: {
          tempPin: {
            url: atob(queryString.get('url')),
            tempFileName: data.downloadPinFile.tempFileName,
            tempFileUrl: data.downloadPinFile.tempFileUrl,
            originalName: data.downloadPinFile.originalName,
          },
          targetBoardID: queryString.get('targetBoardID') || null,
          defaultCaption: getCaptionFromAltAndTitle(),
        },
      })
      //console.log('modal status', modalStatusVar())
    },
    onError: (e) => { console.error(e) },
  })

  const getCaptionFromAltAndTitle = () => {
    const { alt, title } = props.modalOptions
    let caption = null
    if (title && alt) {
      caption = `${title}\n\n${alt}`
    } else {
      caption = title || alt
    }
    return caption
  }

  useEffect(() => {
    downloadPin({ variables: { url: props.modalOptions.src }})
  }, [props.modalOptions.src, downloadPin])

  //console.log('DownloadPinFromScan', props)

  return (
    <ModalSection>
      <ModalHeader
        title="Add a pin from a website"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <FormErrors
          error={error}
        />
        {loading && <Spinner message="Downloading pin..." />}
      </ModalContent>
    </ModalSection>
  )
}


DownloadPinFromScan.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    title: PropTypes.string,
  }),
}


export default withModal(DownloadPinFromScan)
