import PropTypes from 'prop-types'

import Button from '../button/Button'


function OrderPinsButton(props) {
  //console.log('OrderPinsButton', props)
  if (! props.owner) return null

  return (
    <Button
      title={props.title}
      primary={true}
      className={props.className}
      onClick={props.onClick}
    >{props.title}</Button>
  )
}

OrderPinsButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OrderPinsButton
