import withModal from 'hoc/withModal/withModal'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import styles from './choosePinOrigin.module.css'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'


function ChoosePinOrigin(props) {
  return (
    <ModalSection>
      <ModalHeader
        title="Add a pin from"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <div className={styles.chooseOrigin}>
        <button
          onClick={() => modalStatusVar({ type: modalTypes.DOWNLOAD_PIN })}
        >The web</button>
        <button
          onClick={() => modalStatusVar({ type: modalTypes.UPLOAD_PIN })}
        >Your computer</button>
      </div>
    </ModalSection>
  )
}

export default withModal(ChoosePinOrigin)


