import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import Button from '../button/Button'


function UpdateBoardButton(props) {
  //console.log('updateBoardButton', props)
  if (! props.owner) return null

  return (
    <Button
      title="Edit board"
      primary={true}
      className={props.className}
      onClick={() => {
        modalStatusVar({ 
          type: modalTypes.UPDATE_BOARD,
          options: { boardID: props.boardID },
        })
        //console.log('update board', props.boardID)
      }}
    >{props.title || "Edit"}</Button>
  )
}

UpdateBoardButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  boardID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default UpdateBoardButton
