import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import Button from '../button/Button'


function UpdateFolderButton(props) {
  //console.log('updateFolderButton', props)
  if (! props.owner) return null

  return (
    <Button
      title="Edit folder"
      primary={true}
      className={props.className}
      onClick={() => {
        modalStatusVar({
          type: modalTypes.UPDATE_FOLDER,
          options: { folderID: props.folderID }
        })
        //console.log('update folder', props.folderID)
      }}
    >{props.title || "Edit"}</Button>
  )
}

UpdateFolderButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  folderID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default UpdateFolderButton
