import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'


function UpdateEmailForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-email">New email:<FormRequiredField /></label>
        <input
          id="id-email"
          name="email"
          type="email"
          value={props.newEmail}
          onChange={props.handleNewEmailChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <label htmlFor="id-password">Password:<FormRequiredField /></label>
        <input
          id="id-password"
          name="password"
          type="password"
          maxLength="255"
          value={props.password}
          onChange={props.handlePasswordChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>
    </form>
  )
}


UpdateEmailForm.propTypes = {
  form: PropTypes.string.isRequired,
  newEmail: PropTypes.string,
  password: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleNewEmailChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
}


export default UpdateEmailForm
