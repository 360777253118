import PropTypes from 'prop-types'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import styles from './scanItem.module.css'
import RoundCross from '../roundCross/RoundCross'


function ScanItem({ src, alt, title, imageWidth, imageHeight }) {

  const handleClick = () => {
    modalStatusVar({
      type: modalTypes.DOWNLOAD_PIN_FROM_SCAN,
      options: { src, title, alt },
    })
  }

  return (
    <article
      className={styles.scanItem}
      title={`Title: ${title || ''}\n Description: ${alt || ''}`}
    >
      <button
        className={styles.wrapper}
        onClick={handleClick}
      >
        <RoundCross
          className={styles.cross}
          title={`${imageWidth}px × ${imageHeight}px`}
        />
      </button>
      <img
        src={src}
        alt={alt || title || "No description found"}
      />
    </article>
  )
}

ScanItem.propTypes = {
  src: PropTypes.string.isRequired,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
}

export default ScanItem
