import { useState } from 'react'
import {
  useQuery,
  useMutation,
  gql,
} from '@apollo/client'
import { Link } from 'react-router-dom'

import styles from './manageBoardSharedWith.module.css'

import Spinner from 'components/spinner/Spinner'
import FieldWrapper from 'components/fieldWrapper/FieldWrapper'
import FormErrors from 'components/formErrors/FormErrors'
import { BOARD_SHARED_WITH_QUERY } from 'components/boardSharedWith/BoardSharedWith'


const SHARE_BOARD_MUTATION = gql`
  mutation ShareBoard($board: ID!, $username: String!) {
    shareBoard(board: $board, username: $username) {
      message
    }
  }
`

const UNSHARE_BOARD_MUTATION = gql`
  mutation UnshareBoard($board: ID!, $username: String!) {
    unshareBoard(board: $board, username: $username) {
      message
    }
  }
`


export default function ManageBoardSharedWith({ boardID }) {
  const { data, error, loading } = useQuery(BOARD_SHARED_WITH_QUERY, {
    variables: {
      id: boardID
    }
  })

  const [username, setUsername] = useState('')
  const [shareBoard, shareBoardStatus] = useMutation(SHARE_BOARD_MUTATION, {
    refetchQueries: [BOARD_SHARED_WITH_QUERY],
    onCompleted: () => {
      setUsername('')
    },
  })
  const [unshareBoard, unshareBoardStatus] = useMutation(UNSHARE_BOARD_MUTATION, {
    refetchQueries: [BOARD_SHARED_WITH_QUERY],
  })

  if (loading) {
    return (
      <FieldWrapper>
        <Spinner />
      </FieldWrapper>
    )
  }
  if (error) {
    return (
      <FieldWrapper>
        <p>{error}</p>
      </FieldWrapper>
    )
  }

  if (unshareBoardStatus.error) {
    console.error(unshareBoardStatus.error)
  }

  const handleKeyDown = (e) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddSharedUser(username)
    }
  }

  const handleAddSharedUser = (username) => {
    let cleanedUsername = username.trim()
    if (username.startsWith('@')) { // user can add beginning '@', or not
      cleanedUsername = cleanedUsername.slice(1)
    }
    shareBoard({ variables: {
      board: boardID,
      username: cleanedUsername,
    }})
  }

  const handleRemoveSharedUser = (e, username) => {
    e.preventDefault()
    unshareBoard({ variables: {
      board: boardID,
      username,
    }})
  }

  return (
    <FieldWrapper>
      <label htmlFor="id-shared">Shared with</label>
      <ul className={styles.sharedUserList}>
      {data.node.sharedWith.map(sharedUsername =>
        <li 
          className={styles.sharedUserItem}
          key={sharedUsername}
        >
          <Link
            to={`/${sharedUsername}/`}
          >@{sharedUsername}</Link>
          <button
            className={styles.removeButton}
            title="Remove this user"
            onClick={(e) => handleRemoveSharedUser(e, sharedUsername)}
          >×</button>
        </li>
      )}
      </ul>
      <input
        id="id-shared"
        name="title"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="@username"
        onKeyDown={handleKeyDown}
      />
      <FormErrors error={shareBoardStatus.error} />
    </FieldWrapper>
  )
}