import { 
  useQuery,
  gql,
} from '@apollo/client'
import { Link } from 'react-router-dom'

import styles from './boardSharedWith.module.css'

export const BOARD_SHARED_WITH_QUERY = gql`
  query BoardSharedWithQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Board {
        sharedWith
      }
    }
  }
`

export default function BoardSharedWith({ boardID }) {
  const { data, error, loading } = useQuery(BOARD_SHARED_WITH_QUERY, {
    variables: {
      id: boardID
    }
  })

  if (loading) return null
  if (error) {
    return <p>{error}</p>
  }
  if (!data.node.sharedWith || data.node.sharedWith.length === 0) return null

  return (
    <div>
      Shared with:
      {data.node.sharedWith.map(sharedUsername =>
        <Link
          key={sharedUsername}
          to={`/${sharedUsername}/`}
          className={styles.sharedWith}
        >@{sharedUsername}</Link>
      )}
    </div>
  )
}
