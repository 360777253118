import PropTypes from 'prop-types'

import styles from './roundCross.module.css'

function RoundCross(props) {
  return (
    <div
      className={styles.titleCross}
    >
      <div className={`${styles.roundCross} ${props.className ? props.className : ''}`} />
      <h3>{props.title}</h3>
    </div>
  )
}

RoundCross.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

export default RoundCross
