import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import { modalStatusVar, modalTypes } from 'modal'

import withModal from 'hoc/withModal/withModal'

import { NEW_BOARD_FRAGMENT } from '../createBoard/CreateBoard'
import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import UpdateBoardForm from '../updateBoardForm/UpdateBoardForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import DeleteButton from '../deleteButton/DeleteButton'
import { 
  deleteField,
  createItemRef,
  addItemToEdges,
  removeItemFromEdges,
} from 'helpers/GraphqlHelpers'

const UPDATE_BOARD_FORM = 'UPDATE_BOARD_FORM'


const GET_BOARD_QUERY = gql`
query Board($id: ID!) {
  me {
    id
    folders {
      edges {
        id
        name
      }
    }
  }
  node(id: $id) {
    __typename
    ... on Board {
      id
      title
      caption
      secret
      pinDefaultDescription
      pinDefaultTags
      folder {
        id
      }
    }
  }
}
`

const UPDATE_BOARD_MUTATION = gql`
mutation UpdateBoard(
  $id: ID!,
  $title: String,
  $folder: ID, 
  $caption: String, 
  $pinDefaultDescription: String, 
  $pinDefaultTags: [String],
  $secret: Boolean
) {
  updateBoard(
    id: $id,
    title: $title,
    folder: $folder,
    caption: $caption,
    pinDefaultDescription: $pinDefaultDescription,
    pinDefaultTags: $pinDefaultTags,
    secret: $secret
  ) {
    __typename
    id
    slug
    createdAt
    title
    caption
    pinDefaultDescription
    pinDefaultTags
    secret
    folder { id }
    user { id }
  }
}
`



function UpdateBoard(props) {
  const [title, setTitle] = useState('')
  const [folder, setFolder] = useState('')
  const [folders, setFolders] = useState([])
  const [caption, setCaption] = useState('')
  const [pinDefaultDescription, setPinDefaultDescription] = useState('')
  const [pinDefaultTags, setPinDefaultTags] = useState([])
  const [policie, setPolicie] = useState("public")
  
  let id = props.modalOptions && props.modalOptions.boardID ?
    props.modalOptions.boardID : null

  const getBoardStatus = useQuery(GET_BOARD_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ node, me }) => {
      //console.log('data', node, me)
      // We populate state with initial data
      setTitle(node.title || '')
      setCaption(node.caption || '')
      setPinDefaultDescription(node.pinDefaultDescription || '')
      setPinDefaultTags(node.pinDefaultTags || [])
      setPolicie(node.secret ? "private" : "public")
      setFolder(node.folder ? node.folder.id : '')
      setFolders(me.folders.edges)
    }
  })

  const [updateBoard, { loading, error }] = useMutation(UPDATE_BOARD_MUTATION, {
    update: (cache, { data: { updateBoard } }) => {
      let originalFolder = getBoardStatus.data.node.folder ?
        getBoardStatus.data.node.folder.id : null
      if (originalFolder === folder) return
      // we remove board from old folder

      const updatedBoardRef = createItemRef(cache, updateBoard, NEW_BOARD_FRAGMENT)

      function addBoardToCache(cachedBoards) {
        return addItemToEdges(cachedBoards, updatedBoardRef)
      }

      function removeBoardFromCache(cachedBoards, { readField }) {
        return removeItemFromEdges(cachedBoards, readField, updateBoard.id)
      }
      
      if (originalFolder) {
        // we remove board from old folder
        cache.modify({
          id: cache.identify(getBoardStatus.data.node.folder),
          fields: {
            boards: removeBoardFromCache,
            cover1: deleteField,
            cover2: deleteField,
            cover3: deleteField,
            cover4: deleteField,
            cover5: deleteField,
          }
        })
      }
      if (folder) {
        // we add board to new folder
        cache.modify({
          id: cache.identify(updateBoard.folder),
          fields: {
            boards: addBoardToCache,
            cover1: deleteField,
            cover2: deleteField,
            cover3: deleteField,
            cover4: deleteField,
            cover5: deleteField,
          }
        })
      }
    },
    onCompleted: () => {
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    let secret = policie === "private" ? true : false
    updateBoard({ variables: {
      id, title, folder, caption, pinDefaultDescription, pinDefaultTags, secret
    }})
  }

  //console.log('UpdateBoard', props)

  if (getBoardStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getBoardStatus.error) {
    console.error(getBoardStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Edit a board"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <DeleteButton
          title="Delete board"
          onClick={() => {
            modalStatusVar({ 
              type: modalTypes.DELETE_BOARD, 
              options: { ...props.modalOptions },
            })
          }}
        />
        <UpdateBoardForm
          form={UPDATE_BOARD_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleFolderChange={(e) => setFolder(e.target.value)}
          handleCaptionChange={(e) => setCaption(e.target.value)}
          handlePinDefaultDescriptionChange={(e) => setPinDefaultDescription(e.target.value)}
          setPinDefaultTags={setPinDefaultTags}
          handlePolicieChange={(e) => setPolicie(e.target.value)}
          title={title}
          folder={folder}
          folders={folders}
          caption={caption}
          pinDefaultDescription={pinDefaultDescription}
          pinDefaultTags={pinDefaultTags}
          policie={policie}
          error={error}
          id={id}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Updating board..." />}
        <Submit
          primary={true}
          form={UPDATE_BOARD_FORM}
          value="Save changes"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdateBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdateBoard)
