import PropTypes from 'prop-types'
import {
  gql,
  useQuery,
} from '@apollo/client'
import { Link, useParams } from 'react-router-dom'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import grey58 from '../boardAbstract/grey58.png'

import styles from './pinBoards.module.css'

import {
  modalTypes,
  modalStatusVar,
} from '../../modal'


const PIN_BOARDS_QUERY = gql`
  query PinBoardsQuery($pinID: ID!) {
    node(id: $pinID) {
      id
      __typename
      ... on Pin {
        user {
          username
        }
        boards {
          edges {
            id
            slug
            title
            cover1(minWidth: 80, minHeight: 80, format: "jpeg")
          }
        }
      }
    }
  }
`



function PinBoards({ pinID, owner, className, onBoardClick }) {
  const { username, boardSlug } = useParams()
  
  const { loading, data, error } = useQuery(PIN_BOARDS_QUERY, {
    variables: { pinID }
  })

  const handleClick = (e, board) => {
    e.preventDefault()
    //console.log('remove from board', board.id)

    // We redirect to board if we are
    // in the removed board.
    let redirectTo = null
    if (board.slug === boardSlug) {
      redirectTo = `/${username}/boards/${board.slug}/`
      //redirectTo = `/${username}/pins/${pinID}/`
    }
    modalStatusVar({ 
      type: modalTypes.REMOVE_PIN_FROM_BOARD,
      options: {
        pinID: pinID,
        boardID: board.id,
        redirectTo,
      }
    })
  }

  if (loading) return <Spinner />
  if (error) {
    return <ErrorMessage error={error} />
  }

  const boards = data.node.boards.edges
  if (boards.length < 1) return null
  return (
    <ul className={`${styles.boards} ${className}`}>
      {boards.map(board =>
        (<li
          key={board.id}
          className={styles.board}
        ><Link
          to={`/${data.node.user.username}/boards/${board.slug}/`}
          onClick={onBoardClick}
      >{owner ? (<button
        className={styles.overlay}
        title={`Remove pin from board: ${board.title}`}
        onClick={(e) => handleClick(e, board)}
      >×</button>) : null}<img
          src={board.cover1 ? board.cover1 : grey58}
          alt={board.title}
          title={`This pin is on board: ${board.title}`}
        /></Link></li>)
      )}
    </ul>
  )
}

export default PinBoards


PinBoards.propTypes = {
  pinID: PropTypes.string.isRequired,
  owner: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onBoardClick: PropTypes.func,
}

