import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import styles from './resetInput.module.css'

function ResetInput(props) {

  const inputRef = useRef(null)
  return (
    <div className={styles.resetInput} >
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        required={props.required}
        autoFocus={props.autoFocus}
        ref={inputRef}
      />
      <button
        onClick={(e) => {
          e.preventDefault()
          /*
           * Using document.execCommand instead of replacing value
           * allows us to keep browser undo history
           */
          inputRef.current.focus()
          document.execCommand("selectAll")
          document.execCommand("insertText", false, '')
        }}
      >×</button>
    </div>
  )
}

ResetInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default ResetInput
