import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import { showUnsafeVar } from '../pinImage/PinImage'

import styles from './userMenu.module.css'

function UserMenu(props) {
  const showUnsafe = useReactiveVar(showUnsafeVar)

  return (
    <div
      className={styles.overlay}
      onClick={props.close}
    >
      <ul>
        <li><Link
          to={`/${props.username}/pins/`}
        >My pins</Link></li>
        <li><Link
          to={`/${props.username}/`}
        >My boards</Link></li>
        <li><Link
          to={`/${props.username}/folders/`}
        >My folders</Link></li>
        <hr />
        <li><button
          onClick={() => {
            modalStatusVar({ type: modalTypes.CHOOSE_PIN_ORIGIN })
            props.close()
          }}
        >New pin</button></li>
        <li><button
          onClick={() => {
            modalStatusVar({ type: modalTypes.CREATE_BOARD })
            props.close()
          }}
        >New board</button></li>
        <li><button
          onClick={() => {
            modalStatusVar({
              type: modalTypes.CREATE_BOARD, 
              options: { secret: true },
            })
            props.close()
          }}
        >New private board</button></li>
        <li><button
          onClick={() => {
            modalStatusVar({ type: modalTypes.CREATE_FOLDER })
            props.close()
          }}
        >New folder</button></li>
        <li><button
          onClick={() => {
            modalStatusVar({
              type: modalTypes.CREATE_FOLDER,
              options: { secret: true },
            })
            props.close()
          }}
        >New private folder</button></li>
        <hr />
        <li><button
          onClick={() => {
            showUnsafeVar(! showUnsafe)
          }}
        >{ showUnsafe ? "Hide unsafe pins" : "Show unsafe pins"}</button></li>
        <hr />
        <li><Link
          to="/profile/"
        >Profile</Link></li>
        <hr />
        <li><button
          onClick={() => {
            modalStatusVar({ type: modalTypes.LOGOUT })
            props.close()
          }}
        >Sign out</button></li>
      </ul>
    </div>
  )
}

UserMenu.propTypes = {
  close: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default UserMenu
