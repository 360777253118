
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import { modalStatusVar, modalTypes } from 'modal'

import withModal from 'hoc/withModal/withModal'


import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import UpdateFolderForm from '../updateFolderForm/UpdateFolderForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import DeleteButton from '../deleteButton/DeleteButton'

const UPDATE_FOLDER_FORM = 'UPDATE_FOLDER_FORM'


const GET_FOLDER_QUERY = gql`
query Folder($id: ID!) {
  node(id: $id) {
    __typename
    ... on Folder {
      id
      name
      secret
    }
  }
}
`

const UPDATE_FOLDER_MUTATION = gql`
mutation UpdateFolder(
  $id: ID!,
  $name: String!,
  $secret: Boolean
) {
  updateFolder(
    id: $id,
    name: $name,
    secret: $secret
  ) {
    __typename
    id
    slug
    createdAt
    name
    secret
  }
}
`



function UpdateFolder(props) {
  const [name, setName] = useState('')
  const [policie, setPolicie] = useState("public")
  
  let id = props.modalOptions && props.modalOptions.folderID ?
    props.modalOptions.folderID : null

  const getFolderStatus = useQuery(GET_FOLDER_QUERY, {
    variables: { id },
    onCompleted: ({ node }) => {
      //console.log('data', node)
      // We populate state with initial data
      setName(node.name || '')
      setPolicie(node.secret ? "private" : "public")
    }
  })

  const [updateFolder, { loading, error }] = useMutation(UPDATE_FOLDER_MUTATION, {
    onCompleted: (data) => {
      //console.log('updated folder', data)
      props.closeModal()
    },
    onError: (e) => console.error(e),
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    let secret = policie === "private" ? true : false
    updateFolder({ variables: {
      id, name, secret
    }})
  }

  //console.log('UpdateFolder', props)

  if (getFolderStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getFolderStatus.error) {
    console.error(getFolderStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Edit a folder"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <DeleteButton
          title="Delete folder"
          onClick={() => {
            modalStatusVar({ 
              type: modalTypes.DELETE_FOLDER,
              options: { ...props.modalOptions },
            })
          }}
        />
        <UpdateFolderForm
          form={UPDATE_FOLDER_FORM}
          onSubmit={handleSubmit}
          handleNameChange={(e) => setName(e.target.value)}
          handlePolicieChange={(e) => setPolicie(e.target.value)}
          name={name}
          policie={policie}
          error={error}
          id={id}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Updating folder..." />}
        <Submit
          primary={true}
          form={UPDATE_FOLDER_FORM}
          value="Save changes"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdateFolder.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdateFolder)
