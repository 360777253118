import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import useQueryString from '../../hooks/useQueryString'

import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import ColumnGrid from '../columnGrid/ColumnGrid'
import ScanItem from '../scanItem/ScanItem'

import styles from '../boardDetail/boardDetail.module.css'

const PIN_WIDTH = 236

const SCRAPPER_QUERY = gql`
query scrapperQuery($url: String!) {
  scrapper(url: $url) {
    src
    title
    alt
  }
}
`

function Scan() {
  const queryString = useQueryString()
  const [images, setImages] = useState([])
  const base64url = queryString.get('url')
  const url = base64url ? atob(base64url) : null
  
  // TODO if we have no url, we open modal to type one
  const { data, loading, error } = useQuery(SCRAPPER_QUERY, {
    variables: { url },
  })

  useEffect(() => {
    if (! data) return
    setImages([])
    for (let image of data.scrapper) {
      let img = new Image()
      img.src = image.src
      img.onload = () => {
        setImages(actualImages => [...actualImages, {
          ...image,
          imageWidth: img.width,
          imageHeight: img.height,
          displayHeight: Math.round((img.height / img.width) * PIN_WIDTH)
        }])
      }
    }
  }, [data])
  
  if (error) {
    console.error(error)
    //console.log(error.graphQLErrors)
    return <ErrorMessage error={error} />
  }

  if (loading) return <Spinner message={`Scanning "${url}" for images...`}/>

  
  //console.log('Scan', data)

  //console.log('images', images) 
  return (
    <section id="content">
      <header className={styles.header}>
        <h1>Pick an image from:</h1>
        <p className={styles.description}>"{url}"</p>
        <p 
          className={styles.pinCount}
        >{data.scrapper.length} {data.scrapper.length === 1 ? " image" : " images"} found</p>
      </header>
      <section className="columned">
      </section>
      <ColumnGrid
        itemWidth={PIN_WIDTH}
        gutter={14}
      >
        {images.map(image => {
          return (
            <ScanItem
              key={image.src}
              {...image}
              height={image.displayHeight > 120 ? image.displayHeight : 120}
            />
          )
        })}
      </ColumnGrid>
    </section>
  )
}

export default Scan
