import PropTypes from 'prop-types'

import styles from './pinRate.module.css'
import AccessibilityText from '../accessibilityText/AccessibilityText'


function PinRateItem({ step, rating, setRating }) {
  let targetRate, className
  if (step > rating) {
    className = styles.point
    targetRate = step
  } else {
    className = styles.star
    targetRate = rating === step ?
    step - 1 : step
  }
  let star = targetRate === 1 ? 'star' : 'stars'
  let title = `${targetRate} ${star} rating`

  return (
    <button
      className={className}
      title={title}
      onClick={() => setRating(targetRate)}
    >
      <AccessibilityText text={title} />
    </button>
  )
}


function PinRate({ rating, className, setRating }) {
  const rateSteps = [1, 2, 3, 4, 5]

  return (
    <ul className={`${styles.rating} ${className}`}>
      {rateSteps.map(step => (
        <li
          key={step}
        >
          <PinRateItem
            step={step}
            rating={rating}
            setRating={setRating}
          />
        </li>
      ))}
    </ul>
  )
}

PinRate.propTypes = {
  rating: PropTypes.number,
  setRating: PropTypes.func.isRequired,
  className: PropTypes.string,
}


export default PinRate
