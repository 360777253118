
import PropTypes from 'prop-types'

import CreateBoardButton from '../createBoardButton/CreateBoardButton'
import BoardAbstract from '../boardAbstract/BoardAbstract'


function BoardsList(props) {
  //console.log('BoardsList', props)
  return (
    <div>
      <CreateBoardButton
        owner={props.owner}
        private={props.private}
        folderID={props.folderID}
      />
      {props.boards.map(board =>
        (<BoardAbstract
          owner={props.owner}
          key={board.id}
          pinsCount={board.pins.totalCount}
          baseLink={props.baseLink}
          {...board}
        />)
      )}
    </div>
  )
}

BoardsList.propTypes = {
  boards: PropTypes.array.isRequired,
  owner: PropTypes.bool.isRequired,
  private: PropTypes.bool,
  folderID: PropTypes.string,
  baseLink: PropTypes.string.isRequired,
}

export default BoardsList
