import PropTypes from 'prop-types'

import UpdateFolderButton from '../updateFolderButton/UpdateFolderButton'
import RouterLinkButton from '../routerLinkButton/RouterLinkButton'


function FolderButton(props) {

  if (props.owner) return (
    <UpdateFolderButton
      owner={props.owner}
      className={props.className}
      folderID={props.folderID}
    />
  )
  return (
    <RouterLinkButton
      value="View"
      to={props.link}
      title="View board"
      primary={true}
      className={props.className}
    />
  )
}

FolderButton.propTypes = {
  folderID: PropTypes.string,
  owner: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default FolderButton
