
import styles from './modalFooter.module.css'

function ModalFooter(props) {
  return (
    <footer className={styles.modalFooter}>
      {props.children}
    </footer>
  )
}

export default ModalFooter
