import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import styles from '../createPinForm/createPinForm.module.css'

import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreatePinForm from '../createPinForm/CreatePinForm'
import Submit from '../submit/Submit'
import Button from '../button/Button'
import Spinner from '../spinner/Spinner'
import { 
  deleteField,
  createItemRef,
  addItemToEdges,
  addNodeToEdges,
} from 'helpers/GraphqlHelpers'
import { 
  MY_BOARDS_FRAGMENT,
  NEW_PIN_FRAGMENT,
  NEW_BOARD_PIN_EDGE_FRAGMENT,
} from './fragments'

const CREATE_PIN_FORM = 'CREATE_PIN_FORM'



export function getRecentBoardsIDs() {
  const recentBoards = localStorage.getItem('recentBoards')
  if (recentBoards) return recentBoards.split(';')
  return []
}

export function setRecentBoard(boardID) {
  let actualBoards = getRecentBoardsIDs()
  let newBoards = [boardID]

  for (let actualBoard of actualBoards) {
    // we keep only 20 items
    if (actualBoard !== boardID && newBoards.length < 20) {
      newBoards.push(actualBoard)
    }
  }

  localStorage.setItem('recentBoards', newBoards.join(';'))
}

export function getRecentBoardsList(boards) {
  const recentBoardsIDs = getRecentBoardsIDs()
  const recentBoards = []
  for (let board of boards) {
    if (recentBoardsIDs.includes(board.id)) {
      let index = recentBoardsIDs.indexOf(board.id)
      recentBoards[index] = board
    }
  }
  return recentBoards
}

export function getDisabledBoardsIDs(duplicatesBoards) {
  return duplicatesBoards.map(board => board.id)
}

export const ADD_PIN_TO_BOARD = gql`
  mutation AddPinToBoard(
    $pinID: ID!,
    $boardID: ID!
  ) {
    addPinToBoard(pin: $pinID, board: $boardID) {
      created
      boardPinEdge {
        __typename
        id
        cursor
        order
        node {
          id
        }
      }
    }
  }
`




const CREATE_PIN_MUTATION = gql`
  mutation AddPin(
    $tempFileName: String!,
    $originalName: String!,
    $url: String,
    $title: String,
    $caption: String!,
    $rating: Int,
    $unsafe: Boolean,
    $tags: [String],
  ) {
    addPin(
      tempFileName: $tempFileName,
      originalName: $originalName,
      url: $url,
      title: $title,
      caption: $caption,
      rating: $rating,
      unsafe: $unsafe,
      tags: $tags,
    ) {
      __typename
      id
      caption
      sourceFileUrl
      sourceDomain
      rating
      title
      createdAt
      addedVia { id }
      user { id }
      unsafe
      tags
    }
  }
`

const GET_MY_BOARDS_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query MyBoardsQuery {
    ...MyBoardsFragment
  }
`



function CreatePin(props) {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [board, setBoard] = useState('')
  const [recentBoards, setRecentBoards] = useState([])
  const [isDefaultCaption, setIsDefaultCaption] = useState(false)
  const [isDefaultTags, setIsDefaultTags] = useState(false)
  const [unsafe, setUnsafe] = useState(false)

  let tempPin = props.modalOptions.tempPin

  const getMyBoardsStatus = useQuery(GET_MY_BOARDS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const boardID = getDefaultBoardID(data.me.boards.edges)
      setBoard(boardID)
      setRecentBoards(getRecentBoardsList(data.me.boards.edges))
      const boardData =getBoardData(boardID, data.me.boards.edges)
      if (props.modalOptions.defaultCaption) {
        setCaption(props.modalOptions.defaultCaption)
      } else {
        setDefaultCaption(boardData)
      }
      setDefaultTags(boardData)
    }
  })
  
  function setDefaultCaption(board) {
    setIsDefaultCaption(true)
    setCaption(board.pinDefaultDescription || '')
  }

  function setDefaultTags(board) {
    setIsDefaultTags(true)
    setTags(board.pinDefaultTags || [])
  }

  function getBoardData(boardID, boards) {
    for (let board of boards) {
      if (boardID === board.id) {
        return board
      }
    }
  }

  function getDefaultBoardID(boards) {
    const recentBoardsIDs = getRecentBoardsIDs()
    const targetBoardID = props.modalOptions.targetBoardID
    if (targetBoardID) {
      /*
       * If we have a target board (from CreatePinButton click)
       * we return it
       */
      return targetBoardID
    }
    if (recentBoardsIDs.length > 0) {
      /*
       * If we have no target board, we return first
       * not disabled board in recent boards list
       */
      return recentBoardsIDs[0]
    }
    if (boards.length > 0) {
      /* 
       * If we have no valid recent boards, we return first board
       */
      return boards[0].id
    }
    return null
  }

  //console.log('createPin', props)

  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: (cache, { data: { addPinToBoard } }) => {

      // we don't update cache if boardPin hasn't been created
      if (! addPinToBoard.created) return

      const newBoardPinEdgeRef = createItemRef(
        cache, addPinToBoard.boardPinEdge, NEW_BOARD_PIN_EDGE_FRAGMENT
      )

      function addPinBoardEdgeToCache(cachedEdges) {
        return addItemToEdges(cachedEdges, newBoardPinEdgeRef)
      }

      // We update board detail
      cache.modify({
        id: `Board:${board}`,
        fields: {
          pins: addPinBoardEdgeToCache,
          cover1: deleteField,
          cover2: deleteField,
          cover3: deleteField,
          cover4: deleteField,
          cover5: deleteField,
          // we delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('pin added to board', data)
      props.closeModal()
      navigate(`/pins/${data.addPinToBoard.boardPinEdge.node.id}/`)
    },
  })


  const [createPin, createPinStatus] = useMutation(CREATE_PIN_MUTATION, {
    update: (cache, { data: { addPin } }) => {
      const newPinRef = createItemRef(cache, addPin, NEW_PIN_FRAGMENT)
      
      function addPinToCache(cachedPins) {
        return addNodeToEdges(cachedPins, newPinRef, "PinEdge")
      }
       
      // We update user pins list
      cache.modify({
        id: cache.identify(addPin.user),
        fields: {
          pins: addPinToCache,
          // We delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })

      // We update all pins list
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          pins: addPinToCache,
          // We delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })
    },
    onCompleted: (data) => {
      addPinToBoard({variables: {
        pinID: data.addPin.id,
        boardID: board,
      }})
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setRecentBoard(board)
    localStorage.setItem(`lastCaption:${board}`, caption)
    localStorage.setItem(`lastTitle:${board}`, title)
    localStorage.setItem(`lastTags:${board}`, tags.join(' '))
    createPin({variables: {
      title, caption, rating, board, unsafe, tags,
      tempFileName: tempPin.tempFileName,
      originalName: tempPin.originalName,
      url: tempPin.url,
    }})
  }

  const handleBoardChange = (e) => {
    setBoard(e.target.value)
    const boardData = getBoardData(e.target.value, getMyBoardsStatus.data.me.boards.edges)
    if (!caption || isDefaultCaption) {
      setDefaultCaption(boardData)
    }
    if (!tags || tags.length === 0 || isDefaultTags) {
      setDefaultTags(boardData)
    }
  }

  const handleCaptionChange = (e) => {
    setCaption(e.target.value)
    setIsDefaultCaption(false)
  }

  const setTagsWrapper = (tags) => {
    setTags(tags)
    setIsDefaultTags(false)
  }

  if (getMyBoardsStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getMyBoardsStatus.error) {
    //console.error(getMyBoardsStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <p>Sorry, an error occured loading initial data...</p>
      </ModalSection>
    )
  }

  if (getMyBoardsStatus.data.me.boards.edges.length === 0) {
    // We have no board, can't create a pin
    return (
      <ModalSection>
        <ModalHeader
          title="Create pin"
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <h6>Oups...</h6>
          <p>It seems you have no boards, you must create one before adding pins.</p>
        </ModalContent>
        <ModalFooter>
          <Button
            title="Create a board"
            primary={true}
            onClick={() => modalStatusVar({ type: modalTypes.CREATE_BOARD })}
          >Create a board</Button>
        </ModalFooter>
      </ModalSection>
    )
  }

  // TODO delete tempFile mutation on cancel
  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Create a pin"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={CREATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={handleCaptionChange}
          setRating={setRating}
          setTags={setTagsWrapper}
          handleBoardChange={handleBoardChange}
          recentBoards={recentBoards}
          boards={getMyBoardsStatus.data.me.boards.edges}
          preview={tempPin.tempFileUrl}
          title={title}
          board={board}
          caption={caption}
          rating={rating}
          tags={tags}
          error={createPinStatus.error}
          unsafe={unsafe}
          handleUnsafeClick={() => setUnsafe(! unsafe)}
          showLikePreviousButton={true}
        />
      </ModalContent>
      <ModalFooter>
        {createPinStatus.loading && <Spinner message="Creating pin..." />}
        {addPinToBoardStatus.loading && <Spinner message="Adding pin to board..." />}
        <Submit
          primary={true}
          form={CREATE_PIN_FORM}
          value="Pin it"
        />
      </ModalFooter>
    </ModalSection>
  )
}


CreatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    tempPin: PropTypes.shape({
      url: PropTypes.string,
      tempFileName: PropTypes.string.isRequired,
      tempFileUrl: PropTypes.string.isRequired,
      originalName: PropTypes.string.isRequired,
    }),
    targetBoardID: PropTypes.string,
    defaultCaption: PropTypes.string,
  })
}



export default withModal(CreatePin)
