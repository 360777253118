import React, { useState } from 'react'
import {
  useQuery,
  gql,
} from '@apollo/client'

import UserMenu from '../userMenu/UserMenu'
import UserAvatar from '../userAvatar/UserAvatar'

import styles from './userMenuButton.module.css'

import { getUserNames } from '../userDetail/UserDetail'

const USER_MENU_QUERY = gql`
  query UserMenuQuery {
    me {
      id
      username
      firstName
      lastName
    }
  }
`


function UserMenuButton() {
  const [ menu, setMenu ] = useState(false)

  const { loading, data, error } = useQuery(USER_MENU_QUERY)

  if (loading) return null
  if (error) console.error(error)

  function getMenu() {
    if (! menu) return null
    return (
      <UserMenu
        close={() => setMenu(false)}
        username={data.me.username}
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.userMenu}
        onClick={() => setMenu(! menu)}
      ><UserAvatar
        username={data.me.username}
        minSize={32}
      /><div className={styles.userNames}>{getUserNames(data.me)}</div>
      </button>
        {getMenu()}
    </div>
  )
}


export default UserMenuButton
