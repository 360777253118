import PropTypes from 'prop-types'

import FormRequiredField from '../formRequiredField/FormRequiredField'
import FieldWrapper from '../fieldWrapper/FieldWrapper'
import FormErrors from '../formErrors/FormErrors'


function VerifieEmailForm(props) {
  return (
    <form
      id={props.form}
      onSubmit={props.onSubmit}
    >
      <FieldWrapper>
        <label htmlFor="id-token">Token:<FormRequiredField /></label>
        <input
          id="id-token"
          name="token"
          type="text"
          maxLength="255"
          value={props.token}
          onChange={props.handleTokenChange}
          required
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormErrors
          error={props.error}
        />
      </FieldWrapper>
    </form>
  )
}


VerifieEmailForm.propTypes = {
  form: PropTypes.string.isRequired,
  token: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleTokenChange: PropTypes.func.isRequired,
  error: PropTypes.object,
}


export default VerifieEmailForm
