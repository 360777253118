import PropTypes from 'prop-types'
import AccessibilityText from '../accessibilityText/AccessibilityText'

import styles from './userSocialsLinks.module.css'

function UserSocialsLinks(props) {
  let socials = []

  if (props.facebookLink) {
    socials.push({class: styles.fb, link: props.facebookLink,
    alt: "facebook link", title: "Follow on facebook"})
  }
  if (props.flickrLink) {
    socials.push({class: styles.flickr, link: props.flickrLink,
    alt: "flickr link", title: "Follow on flickr"})
  }
  if (props.px500Link) {
    socials.push({class: styles.px500, link: props.px500Link,
    alt: "500px link", title: "Follow on 500px"})
  }
  if (props.twitterLink) {
    socials.push({class: styles.twitter, link: props.twitterLink,
    alt: "twitter link", title: "Follow on twitter"})
  }
  if (props.googleplusLink) {
    socials.push({class: styles.gplus, link: props.googleplusLink,
    alt: "google plus link", title: "Follow on google +"})
  }
  if (props.pinterestLink) {
    socials.push({class: styles.pinterest, link: props.pinterestLink,
    alt: "pinterest link", title: "Follow on pinterest"})
  }
  if (props.vkontakteLink) {
    socials.push({class: styles.vk, link: props.vkontakteLink,
    alt: "vkontakte link", title: "Follow on vkontakte"})
  }
  if (props.instagramLink) {
    socials.push({class: styles.insta, link: props.instagramLink,
    alt: "instagram link", title: "Follow on instagram"})
  }

  if (socials.length === 0) return null
  
  return (
    <ul
      className={`${styles.socialIcons} ${props.className ? props.className : ''}`}
    >
        {socials.map(social => 
          (<li
              key={social.class}
            ><a
             title={social.title}
             href={social.link}
             className={social.class}
             target="_blank"
             rel="noopener noreferrer"
            ><AccessibilityText
              text={`${social.alt} link`}
            /></a></li>
          )
        )}
    </ul>
  )

}


UserSocialsLinks.propTypes = {
  className: PropTypes.string,
  facebookLink: PropTypes.string,
  flickrLink: PropTypes.string,
  px500Link: PropTypes.string,
  twitterLink: PropTypes.string,
  googleplusLink: PropTypes.string,
  pinterestLink: PropTypes.string,
  vkontakteLink: PropTypes.string,
  instagramLink: PropTypes.string,
}

export default UserSocialsLinks
