import { Link } from 'react-router-dom'

export default function PinTags({ tags }) {
  if (!tags || tags.length === 0) return null
  return (
    <div>
      {tags.map(tag => 
        <span key={tag}><Link 
          to={`/search/?tag=${encodeURIComponent(tag)}`}
        >#{tag}</Link> </span>
      )}
    </div>
  )
}