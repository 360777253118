import React, { useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import useQueryString from '../../hooks/useQueryString'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import PasswordResetForm from '../passwordResetForm/PasswordResetForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'

const PASSWORD_RESET_FORM = 'PASSWORD_RESET_FORM'


const PASSWORD_RESET_MUTATION = gql`
  mutation ResetUserPassword($token: String!, $newPassword: String!) {
    resetUserPassword(token: $token, newPassword: $newPassword) {
      id
      username
    }
  }
`

function PasswordReset() {
  const queryString = useQueryString()
  const [localErrors, setLocalErrors] = useState(null)
  // We grab token from query string, if any
  const [token, setToken] = useState(queryString.get("token") || '')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const navigate = useNavigate()
  
  const [passwordReset, { loading, error, client }] = useMutation(PASSWORD_RESET_MUTATION, {
    onCompleted: (data) => {
      // We reset cache
      client.resetStore()
      // We redirect to user home page
      navigate(`/${data.resetUserPassword.username}/`)
    },
    onError: (e) => console.error(e),
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    // we reset local errors
    setLocalErrors(null)
    let localErrors = []
    if (newPassword !== newPasswordConfirm) {
      localErrors.push("New password and new password confirmation must match!")
    }
    if (localErrors.length === 0) {
      passwordReset({ variables: { token, newPassword }})
    } else { setLocalErrors(localErrors) }
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Reset your password"
      />
      <ModalContent>
        <PasswordResetForm
          form={PASSWORD_RESET_FORM}
          onSubmit={handleSubmit}
          handleTokenChange={(e) => setToken(e.target.value)}
          handleNewPasswordChange={(e) => setNewPassword(e.target.value)}
          handleNewPasswordConfirmChange={(e) => setNewPasswordConfirm(e.target.value)}
          token={token}
          newPassword={newPassword}
          newPasswordConfirm={newPasswordConfirm}
          error={error}
          localErrors={localErrors}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Changing password..." />}
        <Submit
          primary={true}
          form={PASSWORD_RESET_FORM}
          value="Change password"
        />
      </ModalFooter>
    </ModalSection>
  )
}


export default PasswordReset


