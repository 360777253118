import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import { modalStatusVar, modalTypes } from 'modal'

import withModal from 'hoc/withModal/withModal'

import styles from '../createPinForm/createPinForm.module.css'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreatePinForm from '../createPinForm/CreatePinForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'

const UPDATE_PIN_FORM = 'UPDATE_PIN_FORM'



const EDIT_PIN_QUERY = gql`
  query EditPin($id: ID!, $minWidth: Int, $format: String!) {
    node(id: $id) {
      __typename
      ... on Pin {
        id
        title
        caption
        rating
        unsafe
        preview(minWidth: $minWidth, format: $format)
        tags
        height
        width
      }
    }
  }
`

const UPDATE_PIN_MUTATION = gql`
  mutation UpdatePin(
    $id: ID!,
    $title: String,
    $caption: String, 
    $rating: Int,
    $unsafe: Boolean,
    $tags: [String],
  ) {
    updatePin(
      id: $id,
      title: $title,
      caption: $caption,
      rating: $rating,
      unsafe: $unsafe,
      tags: $tags,
    ) {
      __typename
      id
      createdAt
      title
      caption
      rating
      unsafe
      tags
    }
  }
`


function UpdatePin(props) {
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [unsafe, setUnsafe] = useState(false)

  let id = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null

  const getPinStatus = useQuery(EDIT_PIN_QUERY, {
    variables: { 
      id,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.node)
      // We populate state with initial data
      setTitle(data.node.title || '')
      setCaption(data.node.caption || '')
      setRating(data.node.rating || 0)
      setUnsafe(data.node.unsafe)
      setTags(data.node.tags || [])
    }
  })

  
  const [updatePin, { loading, error }] = useMutation(UPDATE_PIN_MUTATION, {
    onCompleted: (data) => {
      //console.log('updated pin', data)
      props.closeModal()
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    updatePin({variables: { id, title, caption, rating, unsafe, tags }})
  }


  // console.log('UpdatePin', props)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }
  
  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Edit a pin"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={UPDATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={(e) => setCaption(e.target.value)}
          setRating={setRating}
          setTags={setTags}
          preview={getPinStatus.data.node.preview}
          title={title}
          caption={caption}
          rating={rating}
          tags={tags}
          height={getPinStatus.data.node.height}
          width={getPinStatus.data.node.width}
          error={error}
          unsafe={unsafe}
          handleUnsafeClick={() => setUnsafe(! unsafe)}
          delete={() => {
            modalStatusVar({
              type: modalTypes.DELETE_PIN,
              options: { ...props.modalOptions },
            })
          }}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Editing pin..." />}
        <Submit
          primary={true}
          form={UPDATE_PIN_FORM}
          value="Save changes"
        />
      </ModalFooter>
    </ModalSection>
  )
}


UpdatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.object,
}



export default withModal(UpdatePin)

