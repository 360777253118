import PropTypes from 'prop-types'

import UpdatePinButton from '../updatePinButton/UpdatePinButton'
import SetCoverButton from '../setCoverButton/SetCoverButton'
import PinItButton from '../pinItButton/PinItButton'
import LinkButton from '../linkButton/LinkButton'
import styles from '../pinDetail/pinDetail.module.css'


function PinDetailHeader(props) {
  return (
    <header>
      <LinkButton
        value="Visit site"
        primary={true}
        title="Visit site pin comes from"
        href={props.sourceFileUrl}
        className={styles.headerButton}
      />
      <LinkButton
        value="Image"
        primary={true}
        title="Open original image"
        href={props.original}
        className={styles.headerButton}
      />
      <SetCoverButton
        owner={props.owner}
        pinID={props.pinID}
        boardID={props.boardID}
        className={styles.headerButton}
      />
      <UpdatePinButton
        owner={props.owner}
        pinID={props.pinID}
        className={styles.headerButton}
      />
      <PinItButton
        pinID={props.pinID}
        owner={props.owner}
        className={styles.headerPinButton}
      />
    </header>
  )
}

PinDetailHeader.propTypes = {
  owner: PropTypes.bool.isRequired,
  pinID: PropTypes.string.isRequired,
  boardID: PropTypes.string,
  sourceFileUrl: PropTypes.string,
  original: PropTypes.string,
}

export default PinDetailHeader
