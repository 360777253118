
import { modalTypes } from 'modal'


// Modal components
import CreateBoard from '../createBoard/CreateBoard'
import UpdateBoard from '../updateBoard/UpdateBoard'
import DeleteBoard from '../deleteBoard/DeleteBoard'
import CreateFolder from '../createFolder/CreateFolder'
import UpdateFolder from '../updateFolder/UpdateFolder'
import DeleteFolder from '../deleteFolder/DeleteFolder'
import ChoosePinOrigin from '../choosePinOrigin/ChoosePinOrigin'
import DownloadPin from '../downloadPin/DownloadPin'
import DownloadPinFromScan from '../downloadPinFromScan/DownloadPinFromScan'
import UploadPin from '../uploadPin/UploadPin'
import CreatePin from '../createPin/CreatePin'
import UpdatePin from '../updatePin/UpdatePin'
import DeletePin from '../deletePin/DeletePin'
import DuplicatePin from '../duplicatePin/DuplicatePin'
import AddPinToBoard from '../addPinToBoard/AddPinToBoard'
import RemovePinFromBoard from '../removePinFromBoard/RemovePinFromBoard'
import Logout from '../logout/Logout'
import UploadAvatar from '../uploadAvatar/UploadAvatar'
import UpdatePassword from '../updatePassword/UpdatePassword'
import UpdateEmail from '../updateEmail/UpdateEmail'
import PinClones from '../pinClones/PinClones'


function Modal() {
  return (
    <div>
      <CreateBoard
        type={modalTypes.CREATE_BOARD}
        closable={false}
      />
      <UpdateBoard
        type={modalTypes.UPDATE_BOARD}
        closable={false}
      />
      <DeleteBoard
        type={modalTypes.DELETE_BOARD}
        closable={false}
      />
      <CreateFolder
        type={modalTypes.CREATE_FOLDER}
        closable={false}
      />
      <UpdateFolder
        type={modalTypes.UPDATE_FOLDER}
        closable={false}
      />
      <DeleteFolder
        type={modalTypes.DELETE_FOLDER}
        closable={false}
      />
      <ChoosePinOrigin
        type={modalTypes.CHOOSE_PIN_ORIGIN}
        closable={false}
      />
      <DownloadPin
        type={modalTypes.DOWNLOAD_PIN}
        closable={false}
      />
      <DownloadPinFromScan
        type={modalTypes.DOWNLOAD_PIN_FROM_SCAN}
        closable={false}
      />
      <UploadPin
        type={modalTypes.UPLOAD_PIN}
        closable={false}
      />
      <PinClones
        type={modalTypes.PIN_CLONES}
        closable={false}
      />
      <CreatePin
        type={modalTypes.CREATE_PIN}
        closable={false}
      />
      <UpdatePin
        type={modalTypes.UPDATE_PIN}
        closable={false}
      />
      <DeletePin
        type={modalTypes.DELETE_PIN}
        closable={false}
      />
      <DuplicatePin
        type={modalTypes.DUPLICATE_PIN}
        closable={false}
      />
      <AddPinToBoard
        type={modalTypes.ADD_PIN_TO_BOARD}
        closable={false}
      />
      <RemovePinFromBoard
        type={modalTypes.REMOVE_PIN_FROM_BOARD}
        closable={false}
      />
      <Logout
        type={modalTypes.LOGOUT}
        closable={false}
      />
      <UploadAvatar
        type={modalTypes.UPLOAD_AVATAR}
        closable={false}
      />
      <UpdatePassword
        type={modalTypes.UPDATE_PASSWORD}
        closable={false}
      />
      <UpdateEmail
        type={modalTypes.UPDATE_EMAIL}
        closable={false}
      />
    </div>
  )
}


export default Modal
