import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'

import { getUserNames } from '../userDetail/UserDetail'
import {
  modalTypes,
  modalStatusVar,
} from 'modal'

import FieldWrapper from '../fieldWrapper/FieldWrapper'
import Submit from '../submit/Submit'
import UserProfileForm from '../userProfileForm/UserProfileForm'
import Spinner from '../spinner/Spinner'
import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import Button from '../button/Button'
import UserAvatar from '../userAvatar/UserAvatar'

import styles from './userProfile.module.css'


const PROFILE_FORM = 'PROFILE_FORM'

export const MY_PROFILE_QUERY = gql`
  query MyProfileQuery {
    me {
      id
      username
      lastName
      firstName
      email
      emailVerified
      websiteLink
      facebookLink
      flickrLink
      twitterLink
      px500Link
      googleplusLink
      pinterestLink
      vkontakteLink
      instagramLink
    }
  }
`

const PROFILE_MUTATION = gql`
  mutation UpdateUser(
    $lastName: String,
    $firstName: String,
    $websiteLink: String,
    $facebookLink: String,
    $flickrLink: String,
    $twitterLink: String,
    $px500Link: String,
    $googleplusLink: String,
    $pinterestLink: String,
    $vkontakteLink: String,
    $instagramLink: String,
  ) {
    updateUser(
      lastName: $lastName,
      firstName: $firstName,
      websiteLink: $websiteLink,
      facebookLink: $facebookLink,
      flickrLink: $flickrLink,
      twitterLink: $twitterLink,
      px500Link: $px500Link,
      googleplusLink: $googleplusLink,
      pinterestLink: $pinterestLink,
      vkontakteLink: $vkontakteLink,
      instagramLink: $instagramLink,
    ) {
      id
      username
      lastName
      firstName
      websiteLink
      facebookLink
      flickrLink
      twitterLink
      px500Link
      googleplusLink
      pinterestLink
      vkontakteLink
      instagramLink
    }
  }
`

function UserProfile(props) {
  const navigate = useNavigate()
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [websiteLink, setWebsiteLink] = useState('')
  const [facebookLink, setFacebookLink] = useState('')
  const [flickrLink, setFlickrLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('')
  const [px500Link, setPx500Link] = useState('')
  const [googleplusLink, setGoogleplusLink] = useState('')
  const [pinterestLink, setPinterestLink] = useState('')
  const [vkontakteLink, setVkontakteLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')

  const meStatus = useQuery(MY_PROFILE_QUERY, {
    onCompleted: ({ me }) => {
      if (! me) {
        // User isn't authenticated, redirect to home
        navigate('/')
        return
      }
      setLastName(me.lastName || '')
      setFirstName(me.firstName || '')
      setWebsiteLink(me.websiteLink || '')
      setFacebookLink(me.facebookLink || '')
      setFlickrLink(me.flickrLink || '')
      setTwitterLink(me.twitterLink || '')
      setPx500Link(me.px500Link || '')
      setGoogleplusLink(me.googleplusLink || '')
      setPinterestLink(me.pinterestLink || '')
      setVkontakteLink(me.vkontakteLink || '')
      setInstagramLink(me.instagramLink || '')
    }
  })

  const [profile, { loading, error }] = useMutation(PROFILE_MUTATION, {
    onCompleted: (data) => {
      //console.log('new profile', data)
      // We redirect to user home page after success
      navigate('/' + data.updateUser.username)
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    profile({ variables: {
      lastName, firstName, websiteLink, facebookLink, flickrLink, twitterLink,
      px500Link, googleplusLink, pinterestLink, vkontakteLink,
      instagramLink,
    }})
  }

  if (meStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }

  let me = meStatus.data.me

  if (! me) return null


  return (
    <ModalSection>
      <ModalHeader
        title="Update my profile"
      />
        <ModalContent>
        <div className={styles.detail}>
          <UserAvatar
            username={me.username}
            minSize={80}
            onClick={() =>
              modalStatusVar({ type: modalTypes.UPLOAD_AVATAR })
            }
            title="Change avatar"
          />
          <h2>{getUserNames(me)}</h2>
          <div 
            className={styles.email}
          >
            {me.emailVerified ? <span 
              className={styles.verified}
              title="This email has been verified"
          > ✓</span> : <span
              className={styles.verified}
            ><Link
              to="/verifie_email"
              title="Verifie my email"
            >×</Link></span>}
            <div>{me.email}</div>
          </div>
        </div>
        <div className={styles.adminButtons}>
          <Button
            title="Change my email"
            primary={true}
            onClick={() =>
              modalStatusVar({ type: modalTypes.UPDATE_EMAIL })
            }
          >Change email</Button>
          <Button
            title="Change my password"
            primary={true}
            onClick={() =>
              modalStatusVar({ type: modalTypes.UPDATE_PASSWORD })
            }
          >Change password</Button>
        </div>
        <UserProfileForm
          form={PROFILE_FORM}
          onSubmit={handleSubmit}
          lastName={lastName}
          firstName={firstName}
          websiteLink={websiteLink}
          facebookLink={facebookLink}
          flickrLink={flickrLink}
          twitterLink={twitterLink}
          px500Link={px500Link}
          googleplusLink={googleplusLink}
          pinterestLink={pinterestLink}
          vkontakteLink={vkontakteLink}
          instagramLink={instagramLink}
          handleLastNameChange={(e) => setLastName(e.target.value)}
          handleFirstNameChange={(e) => setFirstName(e.target.value)}
          handleWebsiteLinkChange={(e) => setWebsiteLink(e.target.value)}
          handleFacebookLinkChange={(e) => setFacebookLink(e.target.value)}
          handleFlickrLinkChange={(e) => setFlickrLink(e.target.value)}
          handleTwitterLinkChange={(e) => setTwitterLink(e.target.value)}
          handlePx500LinkChange={(e) => setPx500Link(e.target.value)}
          handleGoogleplusLinkChange={(e) => setGoogleplusLink(e.target.value)}
          handlePinterestLinkChange={(e) => setPinterestLink(e.target.value)}
          handleVkontakteLinkChange={(e) => setVkontakteLink(e.target.value)}
          handleInstagramLinkChange={(e) => setInstagramLink(e.target.value)}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Updating profile..." />}
        <FieldWrapper>
          <Submit
            primary={true}
            form={PROFILE_FORM}
            value="Save"
          />
        </FieldWrapper>
      </ModalFooter>
    </ModalSection>
  )
}

export default UserProfile
