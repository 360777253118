import PropTypes from 'prop-types'

function ColumnItem(props) {
  
  //console.log('ColumnItem', props)
  return (
    <li
      style={{
        transform: `translate(${props.translateX}px, ${props.translateY}px)`,
        opacity: props.display ? 1 : 0,
      }}
    >
      {props.children}
    </li>
  )
}


ColumnItem.propTypes = {
  display: PropTypes.bool,
  translateX: PropTypes.number.isRequired,
  translateY: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default ColumnItem
