import PropTypes from 'prop-types'

import styles from './helpText.module.css'

function HelpText(props) {
  return (
    <span
      className={styles.helpText}
    >{props.text}</span>
  )
}



HelpText.propTypes = {
  text: PropTypes.string.isRequired
}


export default HelpText
