import PropTypes from 'prop-types'

import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'

import withModal from 'hoc/withModal/withModal'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import PinImage from '../pinImage/PinImage'
import PinBoards from '../pinBoards/PinBoards'
import PinItButton from '../pinItButton/PinItButton'
import Spinner from '../spinner/Spinner'

import styles from './pinClones.module.css'


const PIN_CLONE_QUERY = gql`
query PinCloneQuery($pinID: ID!) {
  me { id, username }
  node(id: $pinID) {
    __typename
    ... on Pin {
      boards { totalCount }
    }
  }
}
`
const modalTitle = "Existing pin found with same image"


function PinClones(props) {

  let pin = props.modalOptions.clones[0]

  const { loading, data, error } = useQuery(PIN_CLONE_QUERY, {
    variables: { pinID: pin.id }
  })

  if (loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (error) {
    console.error(error)
    return (
      <ModalSection>
        <ModalHeader
          title={modalTitle}
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  //console.log('PinClones', data)

  const boardsCount = data.node.boards.totalCount
  return (
    <ModalSection>
      <ModalHeader
        title={modalTitle}
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <Link
          to={`/${data.me.username}/pins/${pin.id}`}
          onClick={props.closeModal}
        >
          <div className={styles.preview}>
            <PinImage
              pinID={pin.id}
              alt={pin.caption}
              minWidth={726}
              title="View pin"
            />
          </div>
        </Link>
        {boardsCount > 0 ? (
          <>
          <h6>This pin is on {boardsCount} board{boardsCount > 1 ? 's' : ''}:</h6>
          <PinBoards
            pinID={pin.id}
            owner={false /* to hide delete from board button */} 
            className={styles.boards}
            onBoardClick={props.closeModal}
          />
          </>
        ) : null }

      </ModalContent>
      <ModalFooter>
        <PinItButton
          pinID={pin.id}
          owner={true}
          title="Pin it to another board"
        />
      </ModalFooter>
    </ModalSection>
  )
}


PinClones.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    clones: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      caption: PropTypes.string.isRequired,
    })).isRequired,
  })
}

export default withModal(PinClones)
