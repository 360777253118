import styles from './fieldWrapper.module.css'
  
function FieldWrapper(props) {
  return (
    <div
      className={styles.fieldWrapper}
    >
      {props.children}
    </div>
  )
}


export default FieldWrapper
