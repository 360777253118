import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import withModal from 'hoc/withModal/withModal'

import styles from '../createPinForm/createPinForm.module.css'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import CreatePinForm from '../createPinForm/CreatePinForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'
import {
  getRecentBoardsIDs,
  setRecentBoard,
  getRecentBoardsList,
  ADD_PIN_TO_BOARD,
} from '../createPin/CreatePin'
import { 
  NEW_PIN_FRAGMENT, 
  NEW_BOARD_PIN_EDGE_FRAGMENT,
  MY_BOARDS_FRAGMENT,
} from '../createPin/fragments'
import { 
  deleteField,
  createItemRef,
  addItemToEdges,
  addNodeToEdges,
} from 'helpers/GraphqlHelpers'


const DUPLICATE_PIN_FORM = 'DUPLICATE_PIN_FORM'


export const DUPLICATE_PIN_QUERY = gql`
  ${MY_BOARDS_FRAGMENT}
  query DuplicatePinQuery($id: ID!, $minWidth: Int, $format: String!) {
    ...MyBoardsFragment
    node(id: $id) {
      __typename
      ... on Pin {
        id
        title
        caption
        unsafe
        preview(minWidth: $minWidth, format: $format)
        tags
        width
        height
      }
    }
  }
`

const DUPLICATE_PIN_MUTATION = gql`
mutation DuplicatePin(
  $id: ID!,
  $title: String,
  $caption: String, 
  $rating: Int,
  $unsafe: Boolean,
  $tags: [String],
) {
  duplicatePin(
    pin: $id,
    title: $title,
    caption: $caption,
    rating: $rating,
    unsafe: $unsafe,
    tags: $tags,
  ) {
    id
    createdAt
    title
    caption
    rating
    addedVia { id }
    user { id }
    unsafe
    tags
  }
}
`

function DuplicatePin(props) {
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [rating, setRating] = useState(0)
  const [tags, setTags] = useState([])
  const [board, setBoard] = useState('')
  const [recentBoards, setRecentBoards] = useState([])
  const [unsafe, setUnsafe] = useState(false)

  let id = props.modalOptions && props.modalOptions.pinID ?
    props.modalOptions.pinID : null

  const getPinStatus = useQuery(DUPLICATE_PIN_QUERY, {
    variables: { 
      id,
      minWidth: 650, 
      format: "jpeg"
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //console.log('data', data.node)
      // We populate state with initial data
      setTitle(data.node.title || '')
      setCaption(data.node.caption || '')
      const boardID = getDefaultBoardID(data.me.boards.edges, [])
      setUnsafe(data.node.unsafe)
      setBoard(boardID)
      setRecentBoards(getRecentBoardsList(data.me.boards.edges))
    }
  })

  function getDefaultBoardID(boards, disabledBoardsIDs) {
    const recentBoardsIDs = getRecentBoardsIDs()

    if (recentBoardsIDs.length > 0) {
      /*
       * If we have recent boards, we return first
       * not disabled board in recent boards list
       */
      for (let id of recentBoardsIDs) {
        if (! disabledBoardsIDs.includes(id)) return id
      }
    }
    if (boards.length > 0) {
      /* 
       * If we have no valid recent boards, we return first
       * not disabled board in all boards list
       */
      for (let board of boards) {
        if (! disabledBoardsIDs.includes(board.id)) return board.id
      }
    }
    return null
  }


  const [addPinToBoard, addPinToBoardStatus] = useMutation(ADD_PIN_TO_BOARD, {
    update: (cache, { data: { addPinToBoard } }) => {
      //console.log('addPinToBoard', addPinToBoard)

      // we don't update cache if boardPin hasn't been created
      if (! addPinToBoard.created) return

      const newBoardPinEdgeRef = createItemRef(
        cache, addPinToBoard.boardPinEdge, NEW_BOARD_PIN_EDGE_FRAGMENT
      )

      function addPinBoardEdgeToCache(cachedEdges) {
        return addItemToEdges(cachedEdges, newBoardPinEdgeRef)
      }

      // We update board detail
      cache.modify({
        id: `Board:${board}`,
        fields: {
          pins: addPinBoardEdgeToCache,
          cover1: deleteField,
          cover2: deleteField,
          cover3: deleteField,
          cover4: deleteField,
          cover5: deleteField,
          // we delete single pins, because prev / next ids are not good anymore
          pin: deleteField,
        }
      })
    },
    onCompleted: (data) => {
      //console.log('pin added to board', data)
      props.closeModal()
      // Don't change context it's annoying
      //navigate(`/pins/${data.addPinToBoard.boardPinEdge.node.id}/`)
    },
  })

  const [duplicatePin, duplicatePinStatus] = useMutation(DUPLICATE_PIN_MUTATION, {
    update: (cache, { data : { duplicatePin } }) => {
      
      const newPinRef = createItemRef(cache, duplicatePin, NEW_PIN_FRAGMENT)

      function addPinToCache(cachedPins) {
        return addNodeToEdges(cachedPins, newPinRef, "PinEdge")
      }

      // We update user pins list
      cache.modify({
        id: cache.identify(duplicatePin.user),
        fields: {
          pins: addPinToCache
        }
      })

      // We update all pins list
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          pins: addPinToCache
        }
      })
    },
    onCompleted: (data) => {
      //console.log('duplicated pin', data)
      addPinToBoard({variables: {
        pinID: data.duplicatePin.id,
        boardID: board,
      }})
    },
    onError: (e) => { console.error(e) },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setRecentBoard(board)
    duplicatePin({variables: { id, title, caption, rating, board, unsafe, tags }})
  }


  //console.log('DuplicatePin', props)

  if (getPinStatus.loading) {
    return (
      <ModalSection>
        <Spinner message="Loading initial data..." />
      </ModalSection>
    )
  }
  
  if (getPinStatus.error) {
    console.error(getPinStatus.error)
    return (
      <ModalSection>
        <ModalHeader
          title=""
          close={props.closeModal}
          closeTitle="Cancel"
        />
        <ModalContent>
          <p>Sorry, an error occured loading initial data...</p>
        </ModalContent>
      </ModalSection>
    )
  }

  return (
    <ModalSection className={styles.splitedModal}>
      <ModalHeader
        title="Pin it"
        close={props.closeModal}
        closeTitle="Cancel"
      />
      <ModalContent>
        <CreatePinForm
          form={DUPLICATE_PIN_FORM}
          onSubmit={handleSubmit}
          handleTitleChange={(e) => setTitle(e.target.value)}
          handleCaptionChange={setCaption}
          setRating={setRating}
          setTags={setTags}
          handleBoardChange={(e) => setBoard(e.target.value)}
          recentBoards={recentBoards}
          boards={getPinStatus.data.me.boards.edges}
          preview={getPinStatus.data.node.preview}
          title={title}
          board={board}
          caption={caption}
          rating={rating}
          tags={tags}
          height={getPinStatus.data.node.height}
          width={getPinStatus.data.node.width}
          error={duplicatePinStatus.error}
          unsafe={unsafe}
          handleUnsafeClick={() => setUnsafe(! unsafe)}
          disabledBoardsIDs={[]}
          showLikePreviousButton={true}
        />
      </ModalContent>
      <ModalFooter>
        {duplicatePinStatus.loading && <Spinner message="Duplicating pin..." />}
        {addPinToBoardStatus.loading && <Spinner message="Adding pin to board..." />}
        <Submit
          primary={true}
          form={DUPLICATE_PIN_FORM}
          value="Pin it"
        />
      </ModalFooter>
    </ModalSection>
  )
}


DuplicatePin.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalOptions: PropTypes.shape({
    pinID: PropTypes.string.isRequired,
  }),
}



export default withModal(DuplicatePin)
