import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import styles from './boardAbstract.module.css'

import grey58 from './grey58.png'
import BoardButton from '../boardButton/BoardButton'
import EditBoardCover from '../editBoardCover/EditBoardCover'


function BoardAbstract(props) {
  //console.log('BoardAbstract', props)

  const [editCover, setEditCover] = useState('')
  const link = `${props.baseLink}boards/${props.slug}/`

  return (
    <article className={styles.board}>
      <Link
        to={link}
        title={`More from ${props.title}`}
      >
        <header><h1>{props.title}</h1></header>
        {editCover ? (
            <EditBoardCover
              actualCover={props.cover1}
              closeEdition={() => setEditCover(false)}
              boardID={props.id}
            />
            ) : (
            <div
              className={styles.imgWrapper}
            >
              <img
                src={props.cover1 ? props.cover1 : grey58}
                alt={props.title}
              />
              {props.owner && ! editCover ? (
                <div className={styles.changeCover}>
                  <button
                    className={styles.labelButton}
                    title="Change board cover"
                    onClick={(e) => {
                      e.preventDefault()
                      setEditCover(true)
                    }}
                  >Change cover</button>
                </div>) : null}
              </div>
        )}
        <ul>
          <li><img
            src={props.cover2 ? props.cover2 : grey58}
            alt={props.title}
          /></li>
          <li><img
            src={props.cover3 ? props.cover3 : grey58}
            alt={props.title}
          /></li>
          <li><img
            src={props.cover4 ? props.cover4 : grey58}
            alt={props.title}
          /></li>
          <li><img
            src={props.cover5 ? props.cover5 : grey58}
            alt={props.title}
          /></li>
        </ul>
        <div
          className={styles.label + " " + styles.pinsCount}
        >{props.pinsCount}{props.pinsCount === 1 ? " Pin" : " Pins"}</div>
      </Link>
      <BoardButton
        className={styles.boardButton}
        owner={props.owner}
        link={link}
        boardID={props.id}
      />
    </article>
  )
}

BoardAbstract.propTypes = {
  owner: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  secret: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  pinsCount: PropTypes.number.isRequired,
  cover1: PropTypes.string,
  cover2: PropTypes.string,
  cover3: PropTypes.string,
  cover4: PropTypes.string,
  cover5: PropTypes.string,
  baseLink: PropTypes.string.isRequired,
}

export default BoardAbstract
