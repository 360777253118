import React, { useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import useQueryString from '../../hooks/useQueryString'

import ModalSection from '../modalSection/ModalSection'
import ModalHeader from '../modalHeader/ModalHeader'
import ModalContent from '../modalContent/ModalContent'
import ModalFooter from '../modalFooter/ModalFooter'
import VerifieEmailForm from '../verifieEmailForm/VerifieEmailForm'
import Submit from '../submit/Submit'
import Spinner from '../spinner/Spinner'

const VERIFIE_EMAIL_FORM = 'VERIFIE_EMAIL_FORM'


const VERIFIE_EMAIL_MUTATION = gql`
  mutation VerifieUserEmail($token: String!) {
    verifieUserEmail(token: $token) {
      id
      username
      email
      emailVerified
    }
  }
`

function VerifieEmail() {
  const queryString = useQueryString()
  // We grab token from query string, if any
  const [token, setToken] = useState(queryString.get("token") || '')
  const navigate = useNavigate()
  
  const [verifieEmail, { loading, error }] = useMutation(VERIFIE_EMAIL_MUTATION, {
    onCompleted: (data) => {
      // We redirect to user home page
      navigate(`/${data.verifieUserEmail.username}/`)
    },
    onError: (e) => console.error(e),
  })


  const handleSubmit = (e) => {
    e.preventDefault()
    verifieEmail({ variables: { token }})
  }

  return (
    <ModalSection>
      <ModalHeader
        title="Verify you email"
      />
      <ModalContent>
        <VerifieEmailForm
          form={VERIFIE_EMAIL_FORM}
          onSubmit={handleSubmit}
          handleTokenChange={(e) => setToken(e.target.value)}
          token={token}
          error={error}
        />
      </ModalContent>
      <ModalFooter>
        {loading && <Spinner message="Verifying email..." />}
        <Submit
          primary={true}
          form={VERIFIE_EMAIL_FORM}
          value="Verify"
        />
        {/* TODO Add Send again token button */}
      </ModalFooter>
    </ModalSection>
  )
}


export default VerifieEmail


