import PropTypes from 'prop-types'

import styles from './accessibilityText.module.css'

function AccessibilityText(props) {
  return (
    <span
      className={styles.accessibilityText}
    >{props.text}</span>
  )
}


AccessibilityText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default AccessibilityText
