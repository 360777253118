
import styles from './modalContent.module.css'

function ModalContent(props) {
  return (
    <div className={styles.modalContent}>
      {props.children}
    </div>
  )
}

export default ModalContent
