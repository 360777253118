import {
  useQuery,
  gql,
} from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'

import styles from './pinDetail.module.css'

import useKeyboardNavigation from '../../hooks/useKeyboardNavigation'
import useQueryString from '../../hooks/useQueryString'

import PinDetailHeader from '../pinDetailHeader/PinDetailHeader'
import PinDetailFooter from '../pinDetailFooter/PinDetailFooter'
import PinDetailUser from '../pinDetailUser/PinDetailUser'
import PinAddedVia from '../pinAddedVia/PinAddedVia'
import PinTags from 'components/pinTags/PinTags'
import PinRater from '../pinRater/PinRater'
import PinInfo from '../pinInfo/PinInfo'
import PinImage from '../pinImage/PinImage'
import PinBoards from '../pinBoards/PinBoards'
import ErrorMessage from '../errorMessage/ErrorMessage'
import Spinner from '../spinner/Spinner'
import AccessibilityText from '../accessibilityText/AccessibilityText'
import NavigationBar from '../navigationBar/NavigationBar'


export const PIN_DETAIL_FRAGMENT = gql`
fragment PinDetailFragment on Pin {
  __typename
  id
  createdAt
  sourceFileUrl
  sourceDomain
  title
  caption
  rating
  original
  addedVia { id }
  tags
  user {
    id
    username
    lastName
    firstName
  }
}
`

const BOARD_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
query BoardPinDetailQuery(
  $username: String!, 
  $boardSlug: String!, 
  $pinID: ID!
) {
  me { id }
  user(username: $username) {
    id
    board(slug: $boardSlug) {
      id
      pin(id: $pinID) {
        node {
          ...PinDetailFragment
        }
        cursor
        prevID
        nextID
      }
    }
  }
}
`

const USER_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
query UserPinDetailQuery($username: String!, $pinID: ID!) {
  me { id }
  user(username: $username) {
    id
    pin(id: $pinID) {
      node {
        ...PinDetailFragment
      }
      cursor
      prevID
      nextID
    }
  }
}
`

const ALL_PIN_DETAIL_QUERY = gql`
${PIN_DETAIL_FRAGMENT}
query AllPinDetailQuery($pinID: ID!, $tag: String, $query: String) {
  me { id }
  pin(id: $pinID, tag: $tag, search: $query) {
    node {
      ...PinDetailFragment
    }
    cursor
    prevID
    nextID
  }
}
`


function PinDetail(props) {
  let { username, folderSlug, boardSlug, pinID } = useParams()
  let queryString = useQueryString()
  let query = queryString.get('query')
  let tag = queryString.get('tag')

  const navigate = useNavigate()

  const navigateNext = () => {
    if (pinEdge.nextID) navigate(nextLink)
  }

  const navigatePrev = () => {
    if (pinEdge.prevID) navigate(prevLink)
  }

  const navigateBack = () => {
    navigate(getBackLink())
  }

  const getQuery = () => {
    // We need different queries to have 
    // parent related navigation
    if (username && boardSlug && pinID) {
      // Pin in board context
      return BOARD_PIN_DETAIL_QUERY
    } else if (username && pinID) {
      return USER_PIN_DETAIL_QUERY
    }
    return ALL_PIN_DETAIL_QUERY
  }

  const getBaseLink = () => {
    if (username && folderSlug && boardSlug) {
      // User => Folders => Board context
      return `/${username}/folders/${folderSlug}/boards/${boardSlug}/`
    }
    if (username && boardSlug) {
      // User => Boards context
      return `/${username}/boards/${boardSlug}/`
    }
    if (username) {
      // User pins context
      return `/${username}/pins/`
    }
    if (query || tag) {
      // Search context
      return '/search/'
    }
    // All pins context
    return '/'
  }

  const getQueryString = () => {
    let query = queryString.toString()
    if (query) {
      return `?${query}`
    }
    return ''
  }

  const getBackLink = () => {
    return getBaseLink() + getQueryString()
  }

  const getPrevNextLink = (pinID) => {
    if (! pinID) return null
    let baseLink = getBaseLink()
    if (! baseLink.endsWith('pins/')) {
      // We need to add 'pins/' before pinID except for user pins url
      baseLink += 'pins/'
    }
      
    return `${baseLink}${pinID}/${getQueryString()}`
  }



  useKeyboardNavigation(navigateNext, navigatePrev, navigateBack)

  const { loading, data, error } = useQuery(getQuery(), {
    variables: { username, boardSlug, pinID, tag, query }
  })
 
  if (loading) return <Spinner />
  if (error) {
    console.error(error)
    return <ErrorMessage error={error} />
  }
 
  //console.log('PinDetail', username, folderSlug, boardSlug, pinID, query, tag, data, error)

  let pinEdge = data.pin || data.user?.pin || data.user?.board?.pin
  let boardID = data.user?.board?.id
  let pin = pinEdge.node
  let owner = !!data.me && data.me.id === pin.user.id

  let nextLink = getPrevNextLink(pinEdge.nextID)
  let prevLink = getPrevNextLink(pinEdge.prevID)
  
  return (
    <section id="content">
      <NavigationBar
        backlink={getBackLink()}
      />
      <article className={styles.pinDetail}>
        <PinDetailHeader
          sourceFileUrl={pin.sourceFileUrl}
          original={pin.original}
          pinID={pin.id}
          boardID={boardID}
          owner={owner}
        />
        <Link
          to={`./lightbox/${getQueryString()}`}
        >
          <div className={styles.imageWrapper}>
            <PinImage
              pinID={pin.id}
              alt={pin.caption}
              minWidth={726}
            />
          </div>
        </Link>
        <div className={styles.navigation}>
          <div>{prevLink ? (
            <Link
              className={styles.prevPinLink}
              to={prevLink}
              title="Previous pin"
            > 
              <AccessibilityText text="Previous pin" />
            </Link>
          ) : null}</div>
          <PinRater
            className={styles.rating}
            pinID={pin.id}
            owner={owner}
            rating={pin.rating}
          />
          <div>{nextLink ? (
            <Link
              className={styles.nextPinLink}
              to={nextLink}
              title="Next pin"
            > 
              <AccessibilityText text="Next pin" />
            </Link>
          ) : null}</div>
        </div>
        <section className={styles.captionWrapper}>
          {pin.title ? (<h6>{pin.title}</h6>) : null}
          <p
            className={styles.caption}
          >{pin.caption}</p>
          <PinTags tags={pin.tags} />
        </section>
        <PinInfo pinID={pin.id} />
        <PinBoards
          pinID={pin.id}
          owner={owner}
        />
        <PinDetailFooter
          username={pin.user.username}
          sourceFileUrl={pin.sourceFileUrl}
          sourceDomain={pin.sourceDomain}
        />
      </article>
      <PinDetailUser
        username={pin.user.username}
        createdAt={pin.createdAt}
      />
      { pin.addedVia && pin.addedVia.id ?
        <PinAddedVia
          addedVia={pin.addedVia.id}
        /> : null }
    </section>
  )
}

export default PinDetail
