import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { SET_BOARD_COVER_MUTATION } from '../editBoardCover/EditBoardCover'
import Button from '../button/Button'


function SetCoverButton(props) {
  //console.log('SetCoverButton', props)
  
  const [updateBoard] = useMutation(SET_BOARD_COVER_MUTATION)
  
  if (! props.owner || ! props.pinID || ! props.boardID) return null

  const saveCover = () => {
    updateBoard({
      variables: {
        id: props.boardID,
        cover1: props.pinID,
      }
    })
  }

  return (
    <Button
      title="Set as board cover"
      primary={true}
      className={props.className}
      onClick={saveCover}
    >Cover</Button>
  )

}

SetCoverButton.propTypes = {
  owner: PropTypes.bool.isRequired,
  pinID: PropTypes.string,
  boardID: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
}

export default SetCoverButton
