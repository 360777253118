import styles from './notFound.module.css'

function NotFound(props) {
  return (
    <article className={styles.notFound}>
      <h1>Oups...</h1>
      <p>Sorry, the page you resquested for doesn't seem to exist.</p>
    </article>
  )
}

export default NotFound
