import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import useQueryString from '../../hooks/useQueryString'

import styles from './pinAbstract.module.css'

import PinAbstractRating from '../pinAbstractRating/PinAbstractRating'
import UpdatePinButton from '../updatePinButton/UpdatePinButton'
import PinItButton from '../pinItButton/PinItButton'
import PinImage from '../pinImage/PinImage'


function PinAbstract(props) {
  //console.log('PinAbstract', props)
  const query = useQueryString().toString()
  let link = `${props.baseLink}pins/${props.id}/`
  if (query) {
    link += `?${query}`
  }

  return (
    <article
      className={styles.pin}
      title={`Original size: ${props.originalWidth}px × ${props.originalHeight}px`}
    >
      <div className={styles.mainWrapper}>
        <Link to={link}>
          <PinImage
            pinID={props.id}
            minWidth={236}
            alt={props.caption}
            style={{height: props.imageHeight}}
          />
        </Link>
        <div className={styles.buttonWrapper}>
          <UpdatePinButton
            owner={props.owner}
            pinID={props.id}
            className={styles.headerButton}
          />
          <PinItButton
            pinID={props.id}
            owner={props.owner}
            className={styles.headerButton}
          />
        </div>
        <PinAbstractRating
          owner={props.owner}
          pinID={props.id}
          rating={props.rating}
        />
      </div>
    </article>
  )
}

PinAbstract.propTypes = {
  owner: PropTypes.bool,
  id: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired,
  originalWidth: PropTypes.number.isRequired,
  originalHeight: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  rating: PropTypes.number,
  baseLink: PropTypes.string.isRequired,
}

export default PinAbstract
