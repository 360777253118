import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'


function PinDetailFooter(props) {
  let link
  if (props.sourceDomain && props.sourceFileUrl) {
    link = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.sourceFileUrl}
      ><span>Found on</span> {props.sourceDomain}</a>  
    )
  } else {
    link = (
      <Link
        to={`/${props.username}/`}
      ><span>Uploaded to PinPict by</span> {props.username}</Link>
    )
  }
  
  return (
    <footer>
      {link}
    </footer>
  )
}

PinDetailFooter.propTypes = {
  sourceFileUrl: PropTypes.string,
  sourceDomain: PropTypes.string,
  username: PropTypes.string,
}

export default PinDetailFooter
