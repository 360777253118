import PropTypes from 'prop-types'

import { Link, useLocation } from 'react-router-dom'

import styles from '../boardAbstract/boardAbstract.module.css'
import grey58 from '../boardAbstract/grey58.png'
import FolderButton from '../folderButton/FolderButton'

function FolderAbstract(props) {
  //console.log('FolderAbstract', props)

  const { pathname } = useLocation()
  const link = `${pathname}${props.slug}/`
  
  return (
    <article className={styles.folder}>
      <Link
        to={link}
        title={`More from ${props.name}`}
      >
        <header><h1>{props.name}</h1></header>
        <ul>
          <li>
            <img
              src={props.cover1 ? props.cover1 : grey58}
              alt={props.name}
            />
          </li>
          <li>
            <img
              src={props.cover2 ? props.cover2 : grey58}
              alt={props.name}
            />
          </li>
          <li>
            <img
              src={props.cover3 ? props.cover3 : grey58}
              alt={props.name}
            />
          </li>
          <li>
            <img
              src={props.cover4 ? props.cover4 : grey58}
              alt={props.name}
            />
          </li>
        </ul>
        <div
          className={styles.label + " " + styles.boardsCount}
        >{props.boardsCount}{props.boardsCount === 1 ? " Board" : " Boards"}</div>
      </Link>
      <FolderButton
        className={styles.boardButton}
        owner={props.owner}
        link={link}
        folderID={props.id}
      />
    </article>
  )
}

FolderAbstract.propTypes = {
  owner: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  secret: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cover1: PropTypes.string,
  cover2: PropTypes.string,
  cover3: PropTypes.string,
  cover4: PropTypes.string,
  boardsCount: PropTypes.number.isRequired,
}

export default FolderAbstract
